import React from 'react';
import { Select, Button, Grid, GridItem, Box, Text, Input, Icon, useToast } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';

import xhr from 'xhr';

function AddIndividualStudent({ setIsOpen }) {
  const [loadingButton, setLoadingButton] = React.useState(false);

  const toast = useToast();

  const updateData = async (values, action) => {
    try {
      setLoadingButton(true);
      const response = await xhr.post(`/student-graduateds`, {
        data: {
          ...values,
          valor: values.value,
        },
      });
      setIsOpen(false);
      toast({
        title: 'El estudiante se insertó correctamente!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      action.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Box backgroundColor="white" border="1px solid #e8e8e8" padding="20px" borderRadius="10px">
        <Text fontSize="25px" fontWeight="bold">
          Insertar estudiante
        </Text>

        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            type_document: '',
            document: '',
            institution: '',
            program_finished: '',
            status: '',
            value: '',
            datePayment: '',
            semester: '',
          }}
          onSubmit={updateData}
        >
          <Form>
            <br />
            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box>Nombres</Box>
                <Field marginTop="20px" as={Input} name="name" />
              </GridItem>

              <GridItem colSpan={6}>
                <Box>Tipo Documento</Box>
                <Field marginTop="20px" as={Select} name="type_document">
                  <option value={''}>Seleccione</option>
                  <option value={'CC'}>Cédula de Ciudadanía</option>
                  <option value={'TI'}>Tarjeta de Identidad</option>
                  <option value={'CE'}>Cédula de Extranjería</option>
                  <option value={'PEP'}>PEP</option>
                  <option value={'PPT'}>PPT</option>
                </Field>
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(12, 1fr)" gap={5}>
              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Documento</Text>
                </Box>
                <Field marginTop="20px" type="number" as={Input} name="document" />
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Institución</Text>
                </Box>
                <Field marginTop="20px" as={Select} name="institution">
                  <option value={''}>Seleccione</option>
                  <option value={'INSTITUTO IBEROAMERICANO'}>INSTITUTO IBEROAMERICANO</option>
                  <option value={'INSTITUTO TÉCNICO IBEROAMERICANO'}>INSTITUTO TÉCNICO IBEROAMERICANO</option>
                  <option value={`EDUDIGITAL SAS`}>EDUDIGITAL SAS</option>
                  <option value={`EDUCAMOS CON VALORES`}>EDUCAMOS CON VALORES</option>
                  <option value={`INECOL`}>INECOL</option>
                </Field>
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Programa</Text>
                </Box>
                <Field marginTop="20px" as={Select} name="program_finished">
                  <option value={''}>Seleccione</option>
                  <option value={'Bachillerato Académico'}>Bachillerato Académico</option>
                  <option value={'Tecnico Laboral en Administracion, contable y financiero'}>
                    Tecnico Laboral en Administracion, contable y financiero
                  </option>
                  <option value={'Tecnico Laboral en Primera Infancia'}>Tecnico Laboral en Primera Infancia</option>
                  <option value={'Tecnico Laboral en Diseño Grafico'}>Tecnico Laboral en Diseño Grafico</option>
                  <option value={'Tecnico Laboral en Desarrollo Web'}>Tecnico Laboral en Desarrollo Web</option>
                  <option value={'Tecnico Laboral en Marketing Digital y ventas'}>
                    Tecnico Laboral en Marketing Digital y ventas
                  </option>
                  <option value={'Tecnico Laboral en Salud Ocupacional'}>Tecnico Laboral en Salud Ocupacional</option>
                  <option value={'Programa de Inglés A1, A2, B1 y B2'}>Programa de Inglés A1, A2, B1 y B2</option>
                  <option value={'Bachillerato Adultos'}>Bachillerato Adultos</option>
                  <option value={'Bachillerato Niños Tradicional Anual'}>Bachillerato Niños Tradicional Anual</option>
                  <option value={'Primaria Tradicional Anual'}>Primaria Tradicional Anual</option>
                  {/*  */}
                  <option value={'Conocimientos Académicos en Inglés A1'}>Conocimientos Académicos en Inglés A1</option>

                  <option value={'Conocimientos Académicos en Inglés A2'}>Conocimientos Académicos en Inglés A2</option>

                  <option value={'Conocimientos Académicos en Inglés B1'}>Conocimientos Académicos en Inglés B1</option>

                  <option value={'Conocimientos Académicos en Inglés B2'}>Conocimientos Académicos en Inglés B2</option>

                  <option value={'Tecnico Laboral en Sistemas informaticos y Base de datos'}>
                    Tecnico Laboral en Sistemas informaticos y Base de datos
                  </option>

                  <option value={'Tecnico Laboral en Seguridad Laboral y Ocupacional'}>
                    Tecnico Laboral en Seguridad Laboral y Ocupacional
                  </option>

                  <option value={'Tecnico LAboral en vigilancia y guarda de Seguridad'}>
                    Tecnico Laboral en vigilancia y guarda de Seguridad
                  </option>
                </Field>
              </GridItem>

              <GridItem colSpan={6}>
                <Box marginTop="5px" marginBottom="5px">
                  <Text fontWeight="500">Estado</Text>
                </Box>

                <Field marginTop="20px" as={Select} name="status">
                  <option value={''}>Seleccione</option>
                  <option value={'Aprobado'}>Aprobado</option>
                  <option value={'Retirado'}>Retirado</option>
                  <option value={'Pendiente de pago'}>Pendiente de pago</option>
                </Field>
              </GridItem>

              <GridItem colSpan={3}>
                <Box>Valor (opcional)</Box>
                <Field marginTop="20px" as={Input} name="value" />
              </GridItem>

              <GridItem colSpan={5}>
                <Box>Fecha limite de pago (opcional)</Box>
                <Field marginTop="20px" as={Input} type="date" name="datePayment" />
              </GridItem>

              <GridItem colSpan={4}>
                <Box>Semestre (opcional)</Box>
                <Field marginTop="20px" as={Select} name="semester">
                  <option value={''}>Seleccione</option>
                  <option value={'202401'}>2024-01</option>
                  <option value={'202402'}>2024-02</option>
                  <option value={'2024'}>2024</option>
                  <option value={'202501'}>2025-01</option>
                  <option value={'202502'}>2025-02</option>
                  <option value={'2025'}>2024</option>
                </Field>
              </GridItem>
            </Grid>

            <Button type="submit" palette="primary" marginY="10px" marginX="sm" width="140px" isLoading={loadingButton}>
              Insertar
            </Button>
          </Form>
        </Formik>
      </Box>
    </>
  );
}

export default AddIndividualStudent;
