'use client';
import qs from 'qs';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import xhr from 'xhr';
import Boletin from './boletin';

function ModalFinalResults({ idGrade, idEst, student, resultsData }) {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  const calculateAverageScores = (notesArray) => {
    const subjectScores = {};

    // Normalizar el nombre de cada materia y agrupar las notas
    notesArray.forEach(({ notes }) => {
      notes.forEach(({ score, subject, intensity }) => {
        if (subject) {
          // Normalizar el nombre quitando la parte con el número al final
          const normalizedSubject = subject.replace(/\s\d.*$/, '');

          if (!subjectScores[normalizedSubject]) {
            subjectScores[normalizedSubject] = { totalScore: 0, count: 0, intensity: intensity };
          }
          subjectScores[normalizedSubject].totalScore += Number(score);
          subjectScores[normalizedSubject].count += 1;
        }
      });
    });

    // Transformar el objeto en un array de { name, note }
    const averageScoresArray = Object.keys(subjectScores).map((subject) => {
      const { totalScore, count, intensity } = subjectScores[subject];

      return {
        name: subject,
        note: Number(totalScore) / count,
        intensity: intensity,
      };
    });

    return averageScoresArray;
  };

  useEffect(() => {
    (async () => {
      if (idEst) {
        setLoading(true);

        const res = await xhr(`/student-notes?filters[student][id][$eq]=${idEst}&filters[grade][id][$eq]=${idGrade}`);
        const data = res.data.data;

        const sortedNotes = data.sort((a, b) => {
          if (a.period === 'primer') return -1; // "primer" va primero
          if (b.period === 'primer') return 1;
          return 0; // Mantén el orden para otros valores
        });

        // Resultado
        const averageScores = calculateAverageScores(sortedNotes);

        const getNames = averageScores?.map((el) => el.name);

        const query = qs.stringify(
          {
            filters: {
              title: {
                $in: getNames,
              },
              grade: {
                id: {
                  $eq: idGrade,
                },
              },
              final: {
                $eq: true,
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        const query2 = qs.stringify(
          {
            filters: {
              subject: {
                $in: getNames,
              },
              student: {
                id: {
                  $eq: idEst,
                },
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        const resExam = await xhr(`/quiz-modals?${query}`);
        const dataExam = resExam.data.data;

        const insertExam2 = averageScores.map((el) => ({
          ...el,
          exam: dataExam.find((exam) => exam.title === el.name),
        }));

        const sittingExam = await xhr(`/quiz-modal-sittings?${query2}`);
        const dataSitting = sittingExam.data.data;

        const insertSitting = insertExam2.map((el) => ({
          ...el,
          sitting: dataSitting.find((sitting) => sitting.subject === el.name),
        }));

        setNotes(insertSitting);

        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="">
      <p className="dark:text-white">Ponderado de notas</p>

      <hr />

      <Boletin
        dataResults={notes}
        grade={resultsData[0]?.subject?.grade?.name}
        student={student}
        loadingData={loading}
      />

      <div className="mt-3">
        {loading ? (
          <p>Cargando...</p>
        ) : (
          notes.map((el, index) => {
            const noteFinal = el.sitting
              ? el.sitting.note / 10 > 6
                ? 6
                : el.note < 6
                ? 6
                : el.note > el.sitting.note / 10
                ? el.note.toFixed(2)
                : el.sitting.note / 10
              : el.note.toFixed(2);
            return (
              <Box key={el.index} display="flex">
                <p>
                  {el.name}: <b>{noteFinal}</b> - {noteFinal < 6 ? 'Perdida' : 'Aprobada'}
                </p>
                {el.note < 6 && el.exam && (
                  <div>
                    {el.sitting ? (
                      <div style={{ marginLeft: 4 }}>
                        (Ya el estudiante presentó este examen, nota):{' '}
                        <b>
                          {el.sitting.note / 10 > 6
                            ? '6'
                            : el.note > el.sitting.note / 10
                            ? el.note
                            : el.sitting.note / 10}
                        </b>
                      </div>
                    ) : (
                      <div>No ha presentado recuperacion</div>
                    )}
                  </div>
                )}
              </Box>
            );
          })
        )}
      </div>
    </div>
  );
}

export default ModalFinalResults;
