import { BiTable } from 'react-icons/bi';
import { FaBlog, FaBlogger, FaPodcast } from 'react-icons/fa';
import {
  FiHome,
  FiFilter,
  FiVideo,
  FiUsers,
  FiMail,
  FiDollarSign,
  FiInbox,
  FiFile,
  FiFolder,
  FiUserCheck,
  FiUserPlus,
  FiBook,
  FiUser,
  FiUserMinus,
  FiPaperclip,
} from 'react-icons/fi';

export const LinkItems = [
  {
    name: 'Escritorio',
    icon: FiHome,
    link: '/plataforma/administrador',
    roles: ['admin', 'secretary_admin', 'supervisor_admin', 'marketing', 'community', 'school'],
  },
  {
    name: 'Crear Blog',
    icon: FaBlogger,
    link: '/plataforma/administrador/crear-blog',
    roles: ['community'],
  },
  {
    name: 'Crear Podcast',
    icon: FaPodcast,
    link: '/plataforma/administrador/crear-podcast',
    roles: ['community'],
  },
  {
    name: 'Pizarrón de noticias',
    icon: BiTable,
    link: '/plataforma/administrador/pizarron',
    roles: ['community', 'school'],
  },
  {
    name: 'Prospectos',
    icon: FiFilter,
    link: '/plataforma/administrador/prospects',
    roles: ['marketing'],
  },
  {
    name: 'Filtros',
    icon: FiFilter,
    subMenu: [
      {
        name: 'Cambiar Semestre',
        link: 'filtro/buscar-semestre',
        roles: ['admin', 'supervisor_admin', 'secretary_admin', 'school'],
      },
      {
        name: 'Filtro Estudiantes',
        link: 'filtro/filtro-estudiantes',
        roles: ['admin', 'supervisor_admin', 'secretary_admin', 'school'],
      },
      {
        name: 'Excel',
        link: 'filtro/estudiantes',
        roles: ['admin', 'supervisor_admin', 'secretary_admin'],
      },
      {
        name: 'Subir estudiantes graduados',
        link: 'subir-estudiantes',
        roles: ['admin', 'supervisor_admin', 'secretary_admin'],
      },
      {
        name: 'Lista negra',
        link: 'black-list',
        roles: ['admin', 'supervisor_admin', 'secretary_admin'],
      },
      {
        name: 'Promedio Cuarto Periodo',
        link: 'promedio',
        roles: ['admin', 'supervisor_admin', 'secretary_admin'],
      },
    ],
    roles: ['admin', 'secretary_admin', 'supervisor_admin'],
  },
  {
    name: 'Clases en vivo',
    icon: FiVideo,
    link: '/plataforma/administrador/clases-en-vivo',
    roles: ['admin', 'supervisor_admin', 'school'],
  },
  {
    name: 'Grupos',
    icon: FiUsers,
    link: '/plataforma/administrador/grupos-estudiantes',
    roles: ['admin', 'supervisor_admin', 'school'],
  },
  {
    name: 'Emails',
    icon: FiMail,
    subMenu: [
      {
        name: 'Campañas',
        link: 'emails/envios',
        roles: ['admin', 'supervisor_admin', 'community'],
      },
      {
        name: 'Plantillas',
        link: 'emails/plantillas',
        roles: ['admin', 'supervisor_admin', 'community'],
      },
    ],
    roles: ['admin', 'supervisor_admin', 'community'],
  },
  {
    name: 'Pagos',
    stat: true,
    id: 'allPayments',
    icon: FiDollarSign,
    subMenu: [
      {
        name: 'PayU',
        link: 'pagos',
        stat: true,
        id: 'payments',
        roles: ['admin', 'supervisor_admin'],
      },
      {
        name: 'Facturas de pagos',
        link: 'pagos-facturas',
        stat: false,
        id: 'paymentFactures',
        roles: ['admin', 'supervisor_admin', 'secretary_admin'],
      },
      {
        name: 'Órdenes de compra',
        link: 'ordenes-de-compra',
        stat: true,
        id: 'purchaseOrders',
        roles: ['admin', 'supervisor_admin'],
      },
      {
        name: 'Historial Pagos',
        link: 'historial-de-pagos',
        stat: false,
        id: 'historyPayments',
        roles: ['supervisor_admin', 'school'],
      },
    ],
    roles: ['admin', 'supervisor_admin', 'secretary_admin', 'school'],
  },

  {
    name: 'Buzón de sugerencias',
    icon: FiInbox,
    link: '/plataforma/administrador/feedback',
    stat: true,
    id: 'feedback',
    roles: ['admin', 'supervisor_admin'],
  },

  {
    name: 'Requisitos Pendientes',
    icon: FiFile,
    link: '/plataforma/administrador/requisitos-pendientes',
    stat: true,
    id: 'pendingRequirements',
    roles: ['admin', 'secretary_admin', 'supervisor_admin', 'coordinator', 'school'],
  },

  {
    name: 'Bienvenidas',
    icon: FiUserPlus,
    link: '/plataforma/administrador/bienvenidas',
    stat: true,
    id: 'welcome',
    roles: ['admin', 'supervisor_admin'],
  },
  {
    name: 'Estudiantes Atrasados',
    icon: FiUserMinus,
    link: '/plataforma/administrador/estudiantes-atrasados',
    stat: false,
    id: 'welcome',
    roles: ['admin', 'supervisor_admin'],
  },
  {
    name: 'Matricular estudiantes',
    icon: FiUserPlus,
    link: 'https://klazia.com/matricula',
    stat: false,
    blank: true,
    id: 'welcome',
    roles: ['school'],
  },

  {
    name: 'Lista Convenios',
    icon: FiUserCheck,
    link: '/plataforma/administrador/convenios',
    stat: false,
    id: 'welcome',
    roles: ['admin', 'supervisor_admin'],
  },
  {
    name: 'Profesores',
    icon: FiUser,
    link: '/plataforma/administrador/profesores',
    stat: false,
    id: 'actividades',
    roles: ['admin', 'supervisor_admin'],
  },
  {
    name: 'Actividades AI',
    icon: FiBook,
    link: '/plataforma/administrador/actividades-ai',
    stat: false,
    id: 'actividades',
    roles: ['admin', 'supervisor_admin'],
  },
  {
    name: 'Efecty',
    icon: FiFolder,
    link: '/plataforma/administrador/efecty',
    roles: ['admin'],
  },

  {
    name: 'Afiliados',
    icon: FiUserCheck,
    link: '/plataforma/administrador/afiliados',
    roles: ['admin', 'supervisor_admin'],
  },
];
