import {
  Grid,
  GridItem,
  Image,
  Box,
  Button,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  useToast,
  Switch,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  Input,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Collapsible from 'react-collapsible';

import xhr from 'xhr';
import HistorialTable from './components/historialTable';
import EnrollmentsTable from './components/enrollmentsTable';
import CareerTable from './components/careerTable';
import useSWR from 'swr';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import Loading from 'components/loading/Loading';
import CourseTable from './components/courseTable';
import getInfoStudentAdviser from 'api/adviser';
import useAuth from 'hooks/useAuth';
import PagosTable from './Pagos/pagosTable';

function VerEstudianteAdviser() {
  const [adviserFetching, setAdviserFetching] = React.useState(false);

  let params = useParams();
  const id_est = params.id;
  const toasts = useToast();
  const { user } = useAuth();

  const { data, mutate } = useSWR({ id_est, type: 'getInfoStudentAdviser' }, getInfoStudentAdviser, {
    revalidateOnFocus: false,
  });

  const handleAdviser = async () => {
    try {
      setAdviserFetching(true);
      await xhr.put(`/students/${id_est}`, {
        data: { adviser: user.adviser.id },
      });

      mutate(
        {
          ...data,
          estudiante: { ...estudiante, adviser: { ...user.adviser } },
        },
        false
      );

      toasts({
        title: 'La información se actualizó correctamente!',
        position: 'bottom',
      });
    } catch (error) {
    } finally {
      setAdviserFetching(false);
    }
  };
  if (!data) return <Loading />;

  const { programTypes, affiliate, estudiante } = data;

  return (
    <>
      <Box
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        padding="30px"
        borderRadius="5px"
        backgroundColor="white"
      >
        <Text fontWeight="bold" fontSize="20px">
          {estudiante.user.first_name && (
            <>
              {estudiante.user.first_name.toUpperCase()}&nbsp;
              {estudiante.user.last_name.toUpperCase()}
            </>
          )}
        </Text>
        {estudiante.black_list && (
          <Box bg="red.400" rounded={'10px'} p="10px">
            ESTE ESTUDIANTE ESTÁ EN LA LISTA NEGRA
          </Box>
        )}
        <hr />
        <Grid templateColumns="repeat(9, 1fr)" gap={5}>
          <GridItem colSpan={2}>
            <Box
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
              padding="10px"
              borderRadius="5px"
              backgroundColor="white"
              marginY="10px"
            >
              {estudiante.user.image ? (
                <Image
                  src={`${estudiante.user.image.url}`}
                  width="100%"
                  objectFit="cover"
                  height="150px"
                  borderRadius="10px"
                  marginRight="10px"
                  marginBottom="20px"
                />
              ) : (
                <Box alignX="center" marginBottom="20px">
                  <Flex
                    backgroundColor="#574feb"
                    width="140px"
                    height="140px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="500"
                    fontSize="48px"
                    color="white"
                    margin="auto"
                  >
                    {estudiante.user.first_name[0] && (
                      <>
                        {estudiante.user.first_name[0].toUpperCase()}
                        {estudiante.user.last_name[0].toUpperCase()}
                      </>
                    )}
                  </Flex>
                </Box>
              )}

              <Text marginTop="15px" fontSize="14px">
                Código: {id_est}
                <br />
                Email: {estudiante.user.email}
                <br />
                {estudiante.desease ? (
                  <>
                    <br />
                    <span style={{ backgroundColor: 'red', padding: 3, color: 'white', borderRadius: 5 }}>
                      Condición del estudiante: {estudiante.desease}
                    </span>
                    <br />
                  </>
                ) : (
                  ''
                )}
                <Box>
                  Afiliado:
                  <b>
                    {affiliate?.user?.first_name} {affiliate?.user?.last_name}{' '}
                  </b>
                </Box>
                <br />
                Categoría: {estudiante.user_from} <br />
                {estudiante.user?.country && (
                  <>
                    País: {estudiante.user?.country.name} <br />
                    Indicativo: +{estudiante.user?.country.phone_code}
                    <br />
                  </>
                )}
                Celular: {estudiante.user.cellphone}
                <br />
                SIMAT: <b>{!estudiante.simat ? 'No está matriculado' : estudiante.simat}</b>
                <br />
                Fecha Matricula: {dayjs(estudiante.user.createdAt).format('DD-MM-YYYY')}
                <br />
                Fecha Nacimiento: {estudiante.user.birthdate && dayjs(estudiante.user.birthdate).format('DD-MM-YYYY')}
                <br />
                {estudiante.last_approved_grade && (
                  <Box>
                    Último grado aprobado: <Box fontWeight="600">{estudiante.last_approved_grade}</Box>
                    <br />
                  </Box>
                )}
                {data.parent && (
                  <Box borderY="1px solid gray" marginTop="10px" paddingTop="10px" paddingBottom="0px">
                    <Text fontSize="15px" fontWeight="500">
                      Acudiente: {data.parent.first_name} {data.parent.last_name}
                    </Text>
                    <Box fontWeight="400">
                      Celular: {data.parent.cellphone}
                      <br />
                      Email: {data.parent.email}
                    </Box>
                    <br />
                  </Box>
                )}
                <Box fontSize="17px" marginY="10px">
                  <Link to={`/plataforma/asesoras/ver-estudiante/${id_est}/modificar-estudiante`}>
                    <Button colorScheme="#574feb" backgroundColor="#574feb" size="sm">
                      Actualizar Datos
                    </Button>
                  </Link>

                  <Box borderTop="1px solid gray" paddingY="10px" marginTop="10px">
                    <Box fontSize="14px" fontWeight="500">
                      Asesora
                    </Box>
                    {estudiante.adviser?.id ? (
                      estudiante.adviser.user.first_name
                    ) : (
                      <Button
                        colorScheme="#574feb"
                        borderWidth="1px"
                        borderColor="#574feb"
                        size="sm"
                        variant="outline"
                        marginTop="10px"
                        isLoading={adviserFetching}
                        onClick={handleAdviser}
                      >
                        Asignarme estudiante
                      </Button>
                    )}
                  </Box>
                </Box>
              </Text>
            </Box>
          </GridItem>

          <GridItem colSpan={7}>
            <Grid>
              <GridItem colSpan={12}>
                <Box
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  padding="10px"
                  borderRadius="5px"
                  backgroundColor="white"
                  marginY="10px"
                >
                  <Text fontWeight="500">Programas</Text>
                  <Box maxHeight="300px" overflowY="scroll">
                    {programTypes.map((programType) => {
                      const unique = programType.programs.filter(
                        (el, index, self) => self.findIndex((v) => v.id === el.id) === index
                      );
                      return (
                        <>
                          <Box paddingY="10px" paddingX="10px" key={programType.id}>
                            <Collapsible
                              trigger={
                                <>
                                  <Box
                                    cursor="pointer"
                                    textAlign="left"
                                    marginTop="5px"
                                    background="white"
                                    border="1px solid #3272F1"
                                    padding="10px"
                                    borderRadius="10px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    &nbsp;{programType.name}
                                    <FaCaretDown color="3272F1" />
                                  </Box>
                                </>
                              }
                            >
                              {unique.map((program) => {
                                return (
                                  <>
                                    <Collapsible
                                      key={program.id}
                                      trigger={
                                        <Flex
                                          background="white"
                                          marginLeft="30px"
                                          border="1px solid #3272F1"
                                          borderRadius="10px"
                                          marginTop="10px"
                                          marginBottom="10px"
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Box
                                            cursor="pointer"
                                            padding="10px"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            &nbsp;{program.name}
                                            <FaCaretDown />
                                          </Box>
                                        </Flex>
                                      }
                                    >
                                      <Box textAlign="left" paddingLeft="60px">
                                        <Link
                                          to={`/plataforma/asesoras/ver-estudiante/${id_est}/ver-resultados/${programType.slug}/${program.slug}`}
                                        >
                                          <Box
                                            border="1px solid #3272F1"
                                            borderRadius="10px"
                                            paddingLeft="10px"
                                            paddingRight="10px"
                                            marginBottom="10px"
                                            cursor="pointer"
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Notas
                                            <FaCaretRight />
                                          </Box>
                                        </Link>
                                      </Box>
                                    </Collapsible>
                                  </>
                                );
                              })}

                              <Link
                                to={`/plataforma/asesoras/ver-estudiante/${id_est}/ver-requisitos?program=${programType.id}`}
                              >
                                <Box
                                  background="white"
                                  marginLeft="30px"
                                  border="1px solid #3272F1"
                                  borderRadius="10px"
                                  marginTop="10px"
                                  marginBottom="10px"
                                >
                                  <Box cursor="pointer" padding="10px" display="flex" alignItems="center">
                                    &nbsp;Requisitos
                                    <FaCaretRight />
                                  </Box>
                                </Box>
                              </Link>
                            </Collapsible>
                          </Box>
                          <hr />
                        </>
                      );
                    })}
                  </Box>
                </Box>

                {!programTypes[0] && <Box padding="20px">este estudiante no tiene programas matriculados</Box>}
              </GridItem>
            </Grid>

            <GridItem colSpan={12} marginTop="20px">
              <Tabs isLazy>
                <TabList>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Historial
                  </Tab>

                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Pagos
                  </Tab>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Grados
                  </Tab>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Carreras Técnicas
                  </Tab>
                  <Tab
                    _focus={{ border: 0 }}
                    _selected={{
                      borderBottom: '2px solid #574feb',
                      fontWeight: 'bold',
                      color: '#574feb',
                    }}
                  >
                    Cursos
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel tabId="tab1">
                    <HistorialTable />
                  </TabPanel>
                  <TabPanel tabId="tab1">
                    <PagosTable />
                  </TabPanel>

                  <TabPanel tabId="tab5">
                    <EnrollmentsTable />
                  </TabPanel>

                  <TabPanel tabId="tab6">
                    <CareerTable />
                  </TabPanel>

                  <TabPanel tabId="tab7">
                    <CourseTable />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default VerEstudianteAdviser;
