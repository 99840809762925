import { Box, Button, useDisclosure, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import xhrApi from 'xhr';

export default function SmsTable({ idStudent }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res2 = await xhrApi(
          `/student-documents?filters[student][id][$eq]=${idStudent}&populate=file&sort[0]=createdAt:DESC`
        );
        setData(res2.data.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <TableContainer marginTop="15px" maxHeight="600px" overflowY="auto">
        <Table variant="striped" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>Tipo</Th>
              <Th>Fecha</Th>
              <Th>Archivo</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((el) => {
              return (
                <Tr>
                  <Td>
                    <Box whiteSpace="break-spaces" width="180px">
                      {el.type}
                    </Box>
                  </Td>
                  <Td>{dayjs(el.createdAt).format('YYYY-MM-DD HH:mm')}</Td>
                  <Td>
                    <a href={el.file?.url} target="_blank" rel="noreferrer">
                      <Button cursor="pointer">Ver</Button>
                    </a>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
