import {
  Grid,
  GridItem,
  Image,
  Box,
  Button,
  Flex,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  useToast,
  Switch,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PagosTable from './components/Pagos/pagosTable';
import GuiaTable from './components/guiaTable';
import dayjs from 'dayjs';

import Collapsible from 'react-collapsible';

import xhr from 'xhr';
import HistorialTable from './components/historialTable';
import EnrollmentsTable from './components/enrollmentsTable';
import CareerTable from './components/careerTable';
import EmailsTable from './components/EmailsTable';
import AddStudentBadge from './components/AddStudentBadge';
import ExtraSubjects from './components/ExtraSubjects';
import useSWR from 'swr';
import { FaCaretDown, FaCaretRight, FaCheck } from 'react-icons/fa';
import getInfoStudent from 'api/administrador/student';
import Loading from 'components/loading/Loading';
import CourseTable from './components/courseTable';
import useAuth from 'hooks/useAuth';
import SmsTable from './components/SmsTable';
import ObservadorTable from './components/observador';

function VerEstudiante() {
  const [loading, setLoading] = React.useState(false);

  const [adviserFetching, setAdviserFetching] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const [currentProgram, setCurrentProgram] = React.useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  let params = useParams();
  const id_est = params.id;
  const toasts = useToast();
  const { user } = useAuth();

  const { data, mutate } = useSWR({ id_est, user: user, type: 'getInfoStudentAdmin' }, getInfoStudent, {
    revalidateOnFocus: false,
  });

  const [userFrom, setUserFrom] = React.useState('');

  const handleSimat = async () => {
    try {
      setLoading(true);

      await xhr.put(`/students/${id_est}`, {
        data: {
          simat: data.estudiante?.simat,
        },
      });

      toasts({
        title: 'El simat se actualizó correctamente!',
        position: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDesease = async () => {
    try {
      setLoading(true);

      await xhr.put(`/students/${id_est}`, {
        data: {
          desease: data.estudiante?.desease,
        },
      });

      toasts({
        title: 'Datos actualizados correctamente!',
        position: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSchool = async () => {
    try {
      setLoading(true);

      await xhr.put(`/students/${id_est}`, {
        data: {
          school: data.estudiante?.school,
        },
      });

      toasts({
        title: 'El colegio se actualizó correctamente!',
        position: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdviser = async () => {
    try {
      setAdviserFetching(true);
      await xhr.put(`/students/${id_est}`, {
        data: { adviser: data.estudiante?.adviser?.id },
      });
      toasts({
        title: 'La información se actualizó correctamente!',
        position: 'bottom',
      });
    } catch (error) {
    } finally {
      setAdviserFetching(false);
    }
  };

  const handleBanStudent = (bool) => {
    let letra = '';
    let tof = null;

    if (bool === false) {
      letra = 'activar';
      tof = true;
    } else {
      letra = 'desactivar';

      tof = false;
    }

    const r = window.confirm(`¿Estás seguro de ${letra} a este estudiante?`);

    if (r) banStudent(tof);
  };

  const handleBlackStudent = (bool) => {
    let letra = '';
    let tof = null;

    if (!bool) {
      letra = 'agregar';
      tof = true;
    } else {
      letra = 'quitar';

      tof = false;
    }

    const r = window.confirm(`¿Estás seguro de ${letra} a este estudiante a la lista negra?`);

    if (r) blackStudent(tof);
  };

  const blackStudent = async (tof) => {
    const responseBan = await xhr.put(`/students/${id_est}`, {
      data: {
        black_list: tof,
      },
    });

    mutate(
      {
        ...data,
        estudiante: {
          ...estudiante,
          black_list: responseBan.data.data.black_list,
        },
      },
      false
    );

    toasts({
      title: 'El estudiante se modificó correctamente!',
      position: 'bottom',
    });
  };

  const banStudent = async (tof) => {
    const responseBan = await xhr.put(`/students/${id_est}`, {
      data: {
        active: tof,
      },
    });

    mutate(
      {
        ...data,
        estudiante: { ...estudiante, active: responseBan.data.data.active },
      },
      false
    );

    toasts({
      title: 'El estudiante se modificó correctamente!',
      position: 'bottom',
    });
  };
  const handleCategory = async () => {
    await xhr.put(`/students/${id_est}`, {
      data: {
        user_from: userFrom,
      },
    });
    mutate({ ...data, estudiante: { ...estudiante, user_from: userFrom } }, false);
    setShow(false);
  };

  if (!data) return <Loading />;

  if (data.error) return <Box>{data.error}</Box>;

  const { programTypes, affiliate, estudiante, advisers, badges } = data;

  return (
    <>
      <Box
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
        padding="30px"
        borderRadius="5px"
        backgroundColor="white"
      >
        <Text fontWeight="bold" fontSize="20px">
          {estudiante.user.first_name && (
            <>
              {estudiante.user.first_name.toUpperCase()}&nbsp;
              {estudiante.user.last_name.toUpperCase()}
            </>
          )}
        </Text>

        {estudiante.black_list && (
          <Box bg="red.400" rounded={'10px'} p="10px">
            ESTE ESTUDIANTE ESTÁ EN LA LISTA NEGRA
          </Box>
        )}

        <hr />
        <Grid templateColumns="repeat(9, 1fr)" gap={5}>
          <GridItem colSpan={2}>
            <Box
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
              padding="10px"
              borderRadius="5px"
              backgroundColor="white"
              marginY="10px"
            >
              {estudiante.user.image ? (
                <Image
                  src={`${estudiante.user.image.url}`}
                  width="100%"
                  objectFit="cover"
                  height="150px"
                  borderRadius="10px"
                  marginRight="10px"
                  marginBottom="20px"
                />
              ) : (
                <Box alignX="center" marginBottom="20px">
                  <Flex
                    backgroundColor="#574feb"
                    width="140px"
                    height="140px"
                    borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="500"
                    fontSize="48px"
                    color="white"
                    margin="auto"
                  >
                    {estudiante.user.first_name[0] && (
                      <>
                        {estudiante.user.first_name[0].toUpperCase()}
                        {estudiante.user.last_name[0].toUpperCase()}
                      </>
                    )}
                  </Flex>
                </Box>
              )}

              <Text marginTop="15px" fontSize="14px">
                Código: {id_est}
                <br />
                Email: {estudiante.user.email}
                {estudiante.desease ? (
                  <>
                    <br />
                    <span style={{ backgroundColor: 'red', padding: 3, color: 'white', borderRadius: 5 }}>
                      Condición del estudiante: {estudiante.desease}
                    </span>
                  </>
                ) : (
                  ''
                )}
                <br />
                <Box>
                  Afiliado:{' '}
                  <b>
                    {affiliate?.user?.first_name} {affiliate?.user?.last_name}{' '}
                  </b>
                </Box>
                <Box>
                  <Box
                    onClick={() => {
                      setShow(!show);
                      setUserFrom(estudiante?.user_from);
                    }}
                    cursor="pointer"
                  >
                    Categoría: {!show && estudiante.user_from}
                  </Box>
                  {show && (
                    <InputGroup size="xs">
                      <Input onChange={(e) => setUserFrom(e.target.value)} value={userFrom} />
                      <InputRightAddon onClick={() => handleCategory()} children={<FaCheck />} cursor="pointer" />
                    </InputGroup>
                  )}
                </Box>
                {estudiante.user?.country && (
                  <>
                    País: {estudiante.user?.country.name} <br />
                    Indicativo: +{estudiante.user?.country.phone_code}
                    <br />
                  </>
                )}
                Celular: {estudiante.user.cellphone}
                <br />
                SIMAT: <b>{!estudiante.simat ? 'No está matriculado' : estudiante.simat}</b>
                <br />
                Fecha Matricula: {dayjs(estudiante.user.createdAt).format('DD-MM-YYYY')}
                <br />
                Fecha Nacimiento: {estudiante.user.birthdate && dayjs(estudiante.user.birthdate).format('DD-MM-YYYY')}
                <br />
                {estudiante.last_approved_grade && (
                  <Box>
                    Último grado aprobado: <Box fontWeight="600">{estudiante.last_approved_grade}</Box>
                    <br />
                  </Box>
                )}
                {data.parent && (
                  <Box borderY="1px solid gray" marginTop="10px" paddingTop="10px" paddingBottom="0px">
                    <Text fontSize="15px" fontWeight="500">
                      Acudiente: {data.parent.first_name} {data.parent.last_name}
                    </Text>
                    <Box fontWeight="400">
                      Celular: {data.parent.cellphone}
                      <br />
                      Email: {data.parent.email}
                    </Box>
                    <br />
                  </Box>
                )}
                {['admin', 'supervisor_admin'].includes(user.role.type) &&
                  (estudiante.active === false ? (
                    <Button
                      colorScheme="#574feb"
                      borderColor="#574feb"
                      borderWidth="1px"
                      variant="outline"
                      onClick={() => handleBanStudent(estudiante.active)}
                      size="xs"
                      mt="10px"
                    >
                      Activar
                    </Button>
                  ) : (
                    <Button
                      colorScheme="#574feb"
                      borderColor="#574feb"
                      borderWidth="1px"
                      variant="outline"
                      onClick={() => handleBanStudent(estudiante.active)}
                      size="xs"
                      mt="10px"
                    >
                      Desactivar
                    </Button>
                  ))}
                {['admin', 'supervisor_admin'].includes(user.role.type) &&
                  (!estudiante.black_list ? (
                    <Button
                      colorScheme="#574feb"
                      borderColor="#574feb"
                      borderWidth="1px"
                      variant="outline"
                      onClick={() => handleBlackStudent(estudiante.black_list)}
                      size="xs"
                      mt="10px"
                    >
                      Agregar a la lista negra
                    </Button>
                  ) : (
                    <Button
                      colorScheme="#574feb"
                      borderColor="#574feb"
                      borderWidth="1px"
                      variant="outline"
                      onClick={() => handleBlackStudent(estudiante.black_list)}
                      size="xs"
                      mt="10px"
                    >
                      Quitar de la lista negra
                    </Button>
                  ))}
                <Box fontSize="17px" marginY="10px">
                  <Link to={`/plataforma/administrador/modificar-estudiante/${id_est}`}>
                    <Button colorScheme="#574feb" backgroundColor="#574feb" size="sm">
                      Actualizar Datos
                    </Button>
                  </Link>
                  <Link to={`/plataforma/administrador/ver-estudiante/${id_est}/ver-actividad`}>
                    <Button colorScheme="purple" size="xs" mt="10px">
                      Ver actividades
                    </Button>
                  </Link>
                  <Link to={`/plataforma/administrador/constancias-y-certificados/${id_est}`}>
                    <Button colorScheme="purple" size="xs" mt="10px" ml="10px">
                      Documentos
                    </Button>
                  </Link>

                  {user?.school ? (
                    ''
                  ) : (
                    <>
                      <Box borderTop="1px solid gray" paddingY="10px" marginTop="10px">
                        <Box fontSize="14px" fontWeight="500">
                          Asesora
                        </Box>

                        <Select
                          value={estudiante?.adviser?.id}
                          marginTop="10px"
                          placeholder="Seleccionar"
                          onChange={(e) =>
                            mutate(
                              {
                                ...data,
                                estudiante: {
                                  ...estudiante,
                                  adviser: { id: Number(e.target.value) },
                                },
                              },
                              false
                            )
                          }
                        >
                          {advisers.map((el) => {
                            return (
                              <option value={el.value} key={el.label}>
                                {el.label}
                              </option>
                            );
                          })}
                        </Select>

                        <Button
                          colorScheme="#574feb"
                          borderWidth="1px"
                          borderColor="#574feb"
                          size="sm"
                          variant="outline"
                          marginTop="10px"
                          isLoading={adviserFetching}
                          onClick={handleAdviser}
                        >
                          Actualizar
                        </Button>
                      </Box>

                      <Box
                        borderTop="1px solid gray"
                        borderBottom="1px solid gray"
                        paddingBottom="15px"
                        paddingTop="10px"
                        marginTop="10px"
                      >
                        <Box fontSize="14px" fontWeight="500">
                          Colegio
                        </Box>

                        <Select
                          value={estudiante.school}
                          marginTop="10px"
                          placeholder="Seleccionar"
                          onChange={(e) =>
                            mutate(
                              {
                                ...data,
                                estudiante: {
                                  ...estudiante,
                                  school: e.target.value,
                                },
                              },
                              false
                            )
                          }
                        >
                          <option value="nueva generacion">nueva generacion</option>
                          <option value="san agustin">san agustin</option>
                          <option value="san bartolome">san bartolome</option>
                          <option value="iberoamericano">iberoamericano</option>
                          <option value="klazia international school">klazia international school</option>
                          <option value="educamos con valores">educamos con valores</option>
                          <option value="inecol">inecol</option>
                        </Select>

                        <Button
                          colorScheme="#574feb"
                          borderWidth="1px"
                          borderColor="#574feb"
                          size="sm"
                          variant="outline"
                          marginY="10px"
                          isLoading={loading}
                          onClick={handleSchool}
                        >
                          Actualizar
                        </Button>
                      </Box>

                      <Box borderBottom="1px solid gray" paddingBottom="15px" paddingTop="10px" marginTop="10px">
                        <Box fontSize="14px" fontWeight="500">
                          Estado del SIMAT
                        </Box>

                        <Select
                          value={estudiante.simat}
                          marginTop="10px"
                          placeholder="Seleccionar"
                          onChange={(e) =>
                            mutate(
                              {
                                ...data,
                                estudiante: {
                                  ...estudiante,
                                  simat: e.target.value,
                                },
                              },
                              false
                            )
                          }
                        >
                          <option value="pendiente">Pendiente</option>
                          <option value="matriculado">Matriculado</option>
                          <option value="retirado">Retirado</option>
                          <option value="pendiente_retiro">Pendiente Retiro</option>
                        </Select>

                        <Button
                          colorScheme="#574feb"
                          borderWidth="1px"
                          borderColor="#574feb"
                          size="sm"
                          variant="outline"
                          marginY="10px"
                          isLoading={loading}
                          onClick={handleSimat}
                        >
                          Actualizar
                        </Button>
                      </Box>

                      <Box borderBottom="1px solid gray" paddingBottom="15px" paddingTop="10px" marginTop="10px">
                        <Box fontSize="14px" fontWeight="500">
                          Enfermedad
                        </Box>

                        <Input
                          value={estudiante.desease}
                          marginTop="10px"
                          placeholder="Enfermedad"
                          onChange={(e) =>
                            mutate(
                              {
                                ...data,
                                estudiante: {
                                  ...estudiante,
                                  desease: e.target.value,
                                },
                              },
                              false
                            )
                          }
                        />

                        <Button
                          colorScheme="#574feb"
                          borderWidth="1px"
                          borderColor="#574feb"
                          size="sm"
                          variant="outline"
                          marginY="10px"
                          isLoading={loading}
                          onClick={handleDesease}
                        >
                          Actualizar
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Text>

              {user?.school ? (
                ''
              ) : (
                <Box marginTop="10px">
                  <Box fontSize="14px" fontWeight="500">
                    Insignias
                  </Box>

                  <Flex marginTop="10px" flexWrap="wrap">
                    {badges.map((el, i) => {
                      if (!el.badge) return null;

                      return (
                        <a
                          target="_blank"
                          cursor="pointer"
                          href={`/plataforma/publico/insignias/${el.id}`}
                          rel="noreferrer"
                        >
                          <Image src={el.badge.image?.url} width="60px" marginRight="15px" />
                        </a>
                      );
                    })}
                  </Flex>

                  <AddStudentBadge
                    student={estudiante}
                    studentBadges={badges}
                    mutate={mutate}
                    dataAll={data}
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                  />

                  <Button
                    colorScheme="#574feb"
                    borderWidth="1px"
                    borderColor="#574feb"
                    size="sm"
                    variant="outline"
                    marginY="10px"
                    onClick={() => setIsOpenModal(true)}
                  >
                    Agregar Insignia
                  </Button>
                </Box>
              )}
            </Box>
          </GridItem>

          <GridItem colSpan={7}>
            <Grid>
              <GridItem colSpan={12}>
                <Box
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                  padding="10px"
                  borderRadius="5px"
                  backgroundColor="white"
                  marginY="10px"
                >
                  <Text fontWeight="500">Programas</Text>
                  <Box maxHeight="300px" overflowY="scroll">
                    {programTypes.map((programType) => {
                      const unique = programType.programs.filter(
                        (el, index, self) => self.findIndex((v) => v.id === el.id) === index
                      );
                      return (
                        <>
                          <Box paddingY="10px" paddingX="10px" key={programType.id}>
                            <Collapsible
                              trigger={
                                <>
                                  <Box
                                    cursor="pointer"
                                    textAlign="left"
                                    marginTop="5px"
                                    background="white"
                                    border="1px solid #3272F1"
                                    padding="10px"
                                    borderRadius="10px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    &nbsp;{programType.name}
                                    <FaCaretDown color="3272F1" />
                                  </Box>
                                </>
                              }
                            >
                              {unique.map((program) => {
                                return (
                                  <>
                                    <Collapsible
                                      key={program.id}
                                      trigger={
                                        <Flex
                                          background="white"
                                          marginLeft="30px"
                                          border="1px solid #3272F1"
                                          borderRadius="10px"
                                          marginTop="10px"
                                          marginBottom="10px"
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Box
                                            cursor="pointer"
                                            padding="10px"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            &nbsp;{program.name}
                                            <FaCaretDown />
                                          </Box>
                                        </Flex>
                                      }
                                    >
                                      <Box textAlign="left" paddingLeft="60px">
                                        <Link
                                          to={`/plataforma/administrador/ver-resultados/${programType.slug}/${program.slug}?id=${id_est}`}
                                        >
                                          <Box
                                            border="1px solid #3272F1"
                                            borderRadius="10px"
                                            paddingLeft="10px"
                                            paddingRight="10px"
                                            marginBottom="10px"
                                            cursor="pointer"
                                            display="flex"
                                            alignItems="center"
                                          >
                                            Notas
                                            <FaCaretRight />
                                          </Box>
                                        </Link>

                                        {user?.school ? (
                                          ''
                                        ) : (
                                          <Box
                                            onClick={() => {
                                              setCurrentProgram({
                                                ...program,
                                                program_type: programType,
                                              });
                                              onOpen();
                                            }}
                                            border="1px solid #3272F1"
                                            borderRadius="10px"
                                            cursor="pointer"
                                          >
                                            <Box
                                              paddingLeft="10px"
                                              paddingRight="10px"
                                              display="flex"
                                              alignItems="center"
                                            >
                                              Materias extras
                                              <FaCaretRight />
                                            </Box>
                                          </Box>
                                        )}

                                        <Link
                                          to={`/plataforma/administrador/ver-estudiante/${id_est}/ver-requisitos?program=${program.id}&programType=${programType.id}&type=${programType?.type}`}
                                        >
                                          <Box
                                            border="1px solid #3272F1"
                                            borderRadius="10px"
                                            paddingLeft="10px"
                                            paddingRight="10px"
                                            marginBottom="10px"
                                            cursor="pointer"
                                            display="flex"
                                            alignItems="center"
                                            mt="10px"
                                          >
                                            &nbsp;Requisitos
                                            <FaCaretRight />
                                          </Box>
                                        </Link>
                                      </Box>
                                    </Collapsible>
                                  </>
                                );
                              })}
                            </Collapsible>
                          </Box>
                          <hr />
                        </>
                      );
                    })}
                  </Box>
                </Box>

                {!programTypes[0] && <Box padding="20px">este estudiante no tiene programas matriculados</Box>}
              </GridItem>
            </Grid>

            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <ExtraSubjects currentProgram={currentProgram} studentId={id_est} />
                </ModalBody>
              </ModalContent>
            </Modal>

            <GridItem colSpan={12} marginTop="20px">
              <Tabs isLazy={true}>
                {user?.school ? (
                  <TabList>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Historial
                    </Tab>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Pagos
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Grados
                    </Tab>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Carreras Técnicas
                    </Tab>
                  </TabList>
                ) : (
                  <TabList>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Observador
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Historial
                    </Tab>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Pagos
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Emails
                    </Tab>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Documentos
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Nº Guía
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Grados
                    </Tab>
                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Carreras Técnicas
                    </Tab>

                    <Tab
                      _focus={{ border: 0 }}
                      _selected={{
                        borderBottom: '2px solid #574feb',
                        fontWeight: 'bold',
                        color: '#574feb',
                      }}
                    >
                      Cursos
                    </Tab>
                  </TabList>
                )}
                {user?.school ? (
                  <TabPanels>
                    <TabPanel>
                      <HistorialTable />
                    </TabPanel>
                    <TabPanel>
                      <PagosTable programTypes={programTypes} advisers={advisers} />
                    </TabPanel>
                    <TabPanel>
                      <EnrollmentsTable />
                    </TabPanel>
                    <TabPanel>
                      <CareerTable />
                    </TabPanel>
                  </TabPanels>
                ) : (
                  <TabPanels>
                    <TabPanel>
                      <ObservadorTable />
                    </TabPanel>

                    <TabPanel>
                      <HistorialTable />
                    </TabPanel>
                    <TabPanel>
                      <PagosTable programTypes={programTypes} advisers={advisers} />
                    </TabPanel>

                    <TabPanel>
                      <EmailsTable />
                    </TabPanel>
                    <TabPanel>
                      <SmsTable cellphoneStudent={estudiante.user.cellphone} idStudent={estudiante.id} />
                    </TabPanel>
                    <TabPanel>
                      <GuiaTable />
                    </TabPanel>

                    <TabPanel>
                      <EnrollmentsTable />
                    </TabPanel>

                    <TabPanel>
                      <CareerTable />
                    </TabPanel>
                    <TabPanel>
                      <CourseTable />
                    </TabPanel>
                  </TabPanels>
                )}
              </Tabs>
            </GridItem>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default VerEstudiante;
