'use client';

import jsPDF from 'jspdf';
import { useState } from 'react';

import { capitalize } from './capitalize';
import dayjs from 'dayjs';
import { formatCurrency } from './formatCurrency';
import { Button } from '@chakra-ui/react';

function Boletin({ student, dataResults, grade, loadingData }) {
  const [loading, setLoading] = useState(false);

  const simplificaciones = {
    'Cédula de Ciudadanía': 'CC',
    'Cédula de Extranjería': 'CE',
    'Licencia de conducir': 'LC',
    Pasaporte: 'PP',
    'Permiso especial de permanencia': 'PEP',
    'Registro civil': 'RC',
    'Tarjeta de Identidad': 'TI',
  };

  const typeDoc = simplificaciones[student.identity_type?.name];

  // Calcular el promedio

  const generarCertificado = async () => {
    // Crea un nuevo documento PDF
    try {
      setTimeout(() => {
        const sinGrado = grade.replace(/grado:\s*|\s*\(.*?\)/gi, '');
        const soloText = sinGrado.split(' ')[0];

        const gradeText = capitalize(soloText);

        const promovido = {
          Primero: 'Segundo',
          Segundo: 'Tercero',
          Tercero: 'Cuarto',
          Cuarto: 'Quinto',
          Quinto: 'Sexto',
          Sexto: 'Séptimo',
          Séptimo: 'Octavo',
          Octavo: 'Noveno',
          Noveno: 'Décimo',
          Décimo: 'Undécimo',
          Decimo: 'Undécimo',
          Once: 'Graduado',
          Undécimo: 'Graduado',
          Undecimo: 'Graduado',
        };

        const gradeNumber = {
          Primero: '1',
          Segundo: '2',
          Tercero: '3',
          Cuarto: '4',
          Quinto: '5',
          Sexto: '6',
          Séptimo: '7',
          Octavo: '8',
          Noveno: '9',
          Décimo: '10',
          Decimo: '10',
          Once: '11',
          Undécimo: '11',
          Undecimo: '11',
        };

        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [2000, 1414],
          filters: ['ASCIIHexEncode'],
        });
        doc.setProperties({
          title: `Certificado Final `,
          author: 'Klazia Corp',
          creator: 'Klazia Corp',
        }); // Establece el título del PDF

        doc.addImage(
          `${
            student?.school === 'san bartolome'
              ? `/certificado-san-bartolome.webp`
              : student?.school === 'nueva generacion'
              ? '/certificado-nueva-generacion.webp'
              : `/certificado-san-agustin.webp`
          }`,
          'xJPEG',
          0,
          0,
          1414,
          2000
        );

        doc.setFontSize(31);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${student?.user?.first_name.toUpperCase()} ${student?.user?.last_name.toUpperCase()}`, 100, 495);
        doc.text(`${typeDoc}`, 743, 495);
        doc.text(`${formatCurrency(student?.identity)}`, 843, 495);
        doc.text(`${student?.expedition_place}`, 1143, 495);
        doc.text(`2024`, 345, 530);
        doc.text(`${gradeText} (${gradeNumber[gradeText]}°)`, 510, 530);
        doc.text(
          `${
            gradeText === 'Decimo' ||
            gradeText === 'Décimo' ||
            gradeText === 'Undécimo' ||
            gradeText === 'Undecimo' ||
            gradeText === 'Once'
              ? 'de Educación Media Secundaria'
              : gradeText === 'Primero' ||
                gradeText === 'Segundo' ||
                gradeText === 'Tercero' ||
                gradeText === 'Cuarto' ||
                gradeText === 'Quinto'
              ? 'de Educación Básica Primaria'
              : 'de Educación Básica Secundaria'
          }`,
          690,
          530
        );

        const init = 775;

        ////3 linea (Materias) se le suma 55 por linea
        dataResults?.forEach((el, index) => {
          let textoLimpio = el.name;

          const yOffset = index < 3 ? 40 : index < 5 ? 38 : index < 7 ? 37 : 36; // Usar 55 para las primeras 5 iteraciones, luego 40
          const finalNote = el?.sitting ? (el?.sitting?.note < 60 ? 6 : 6) : el.note < 6 ? 6 : el.note;
          doc.text(`${textoLimpio}`, 115, init + yOffset * index);
          doc.text(`${finalNote.toFixed(1)}`, 870, init + yOffset * index);
          doc.text(`${el.intensity ? el.intensity : ''}`, 640, init + yOffset * index);
          const desempeno = finalNote;
          doc.text(
            `${
              desempeno <= 6.4
                ? 'BAJO'
                : desempeno >= 6.5 && desempeno <= 8.4
                ? 'BÁSICO'
                : desempeno >= 8.5 && desempeno <= 9.4
                ? 'ALTO'
                : 'SUPERIOR'
            }`,
            1130,
            init + yOffset * index,
            {
              align: 'center',
            }
          );
        });
        doc.text(
          `${promovido[gradeText]} ${
            promovido[gradeText] === 'Graduado' ? '' : `(${Number(gradeNumber[gradeText]) + 1}°)`
          }`,
          530,
          1460
        );
        doc.text(`${student?.school === 'san bartolome' ? `Bucaramanga` : `Floridablanca`}`, 525, 1855);
        doc.text(`${capitalize(dayjs().format('DD'))}`, 780, 1855);
        doc.text(`${capitalize(dayjs().format('MMM'))}`, 1000, 1855);
        doc.text(`${capitalize(dayjs().format('YYYY'))}`, 1100, 1855);

        // Guarda el PDF (reemplaza 'nombre_del_archivo.pdf' con el nombre deseado)

        // Generar un Blob para el PDF
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Abrir en una nueva pestaña
        const newWindow = window.open(pdfUrl, '_blank');
        if (!newWindow) {
          alert('Por favor, habilite los pop-ups para esta página.');
        } else {
          newWindow.focus();
        }

        // doc.save(`Boletin-${student?.user?.first_name}.pdf`);
        setTimeout(() => {
          setLoading(false); // Ocultar el ícono de carga
        }, 100); // Ajusta el tiempo según sea necesario
      }, 2000);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        colorScheme="messenger"
        isDisabled={loading || loadingData}
        isLoading={loading || loadingData}
        size={'sm'}
        marginY={3}
        onClick={() => {
          generarCertificado();
          setLoading(true);
        }}
        className="bg-primary text-white dark:bg-klazia dark:text-black md:!text-sm !text-xs !md:px-3 !md:py-3 !px-2 !py-2 !m-0"
      >
        Descargar Certificado
      </Button>
    </div>
  );
}

export default Boletin;
