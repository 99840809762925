'use client';

import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  Textarea,
  useToast,
  Radio,
  RadioGroup,
  Stack,
  Input,
  Select,
  Flex,
} from '@chakra-ui/react';
import jsPDF from 'jspdf';
import { Link, useParams } from 'react-router-dom';

import xhr from 'xhr';

const certificateTypes = {
  constanciaPrincipal: {
    text: `Que, el estudiante [NOMBRE DEL ESTUDIANTE], identificado(a) con la [TIPO DE DOCUMENTO] N.º [NÚMERO DE DOCUMENTO] de [LUGAR DE EXPEDICIÓN], se encuentra cursando el [GRADO] del nivel [NIVEL EDUCATIVO], bajo el Calendario A, correspondiente al período lectivo del año [AÑO].  
\n\nEl estudiante desarrolla su formación, cumpliendo con todas las actividades académicas establecidas, con una intensidad horaria de 22 horas semanales.\n\nLa presente constancia se expide a solicitud del/de la interesado(a) el día 05 de febrero de 2025, para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia Principal',
  },
  constanciaGradoProximo: {
    text: `Que [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], ha finalizado el [GRADO] del [NIVEL EDUCATIVO] en nuestra institución, correspondiente al segundo periodo lectivo del año [AÑO ANTERIOR], bajo la modalidad [MODALIDAD].  
\nEl/la estudiante ha cumplido satisfactoriamente con todas las actividades académicas establecidas y se encuentra en proceso de certificación, estando programada la entrega de su diploma de bachiller a partir del [FECHA ENTREGA DIPLOMA].  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Contancia de Próximo Grado',
  },
  constanciaDeMatriculaParaRetiroAnteriorColegio: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], se encuentra actualmente en proceso de matrícula en nuestra institución.  
\nSin embargo, dicho proceso no ha podido completarse debido a la falta de presentación del soporte de desvinculación del SIMAT de la institución de procedencia.  
\nPor lo anterior, solicitamos atentamente que se lleve a cabo este trámite a la mayor brevedad posible, a fin de no afectar la continuidad académica del/la estudiante.  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia de matricula para retiro de anterior colegio',
  },

  pazysalvo: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], ha finalizado satisfactoriamente el Ciclo IV (Grados 8º y 9º) en nuestra institución, cumpliendo con todas las actividades académicas establecidas, con una intensidad horaria de 22 horas semanales.  
\nAsimismo, se deja constancia de que el/la estudiante se encuentra a paz y salvo en todo concepto.  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Paz y Salvo',
  },

  constanciaServicioSocial: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], se encuentra actualmente finalizando el Ciclo VI (Grado 11°) en nuestra institución.  
\nComo parte del cumplimiento de los requisitos de formación, los estudiantes deben desarrollar su Servicio Social Estudiantil, el cual consta de 80 horas de labor comunitaria.  
\nPor lo anterior, solicitamos atentamente que se le brinde la oportunidad de realizar dichas horas con ustedes, en aquellas actividades en las que consideren que pueda prestar apoyo y ser de utilidad.  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia Servicio Social',
  },
  certificadoDeConducta: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], se encuentra actualmente cursando el Bachillerato Académico en nuestra institución, cumpliendo de manera satisfactoria con todas las actividades académicas establecidas, con una intensidad horaria de 22 horas semanales.  
\nAsimismo, se destaca por mantener una Conducta Superior, reflejando compromiso, responsabilidad y buen desempeño en su proceso de formación.  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Certificado de Conducta',
  },
  constanciaNoRegistroSIMAT: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], ha finalizado satisfactoriamente su Bachillerato Académico en nuestra institución, cumpliendo con todas las actividades académicas establecidas.  
No obstante, debido a que no se lograron reunir todos los requisitos de matrícula dentro del plazo establecido, no fue posible registrar su información en la plataforma SIMAT. A pesar de esta situación, su diploma es plenamente válido y ha sido expedido por nuestra institución.  

Dado que esta circunstancia obedece a un proceso administrativo ajeno al estudiante, solicitamos atentamente que esta situación no sea considerada como un impedimento en futuras solicitudes que él/ella requiera realizar.  

Los documentos que acreditan su grado están debidamente registrados en nuestros archivos institucionales, bajo los siguientes datos:  

Libro de Diplomas Otorgados: No. [LIBRO]  
Folio: No. [FOLIO]  
Diploma: No. [DIPLOMA]  
Acta General de Graduación: No. [ACTA]  
Grupo de Bachilleres: [GRUPO]  
Fecha de Expedición: [FECHA DE EXPEDICIÓN DEL DIPLOMA]  

Agradecemos que su documentación sea tomada en cuenta para cualquier trámite legal correspondiente.  

La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia de no registro al SIMAT',
  },

  constanciaDeGraduacionyPension: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], cursó y aprobó el Ciclo VI (11º) del Bachillerato Académico en nuestra institución, obteniendo su grado el [FECHA DE GRADUACIÓN].  
El estudiante cumplió con la totalidad de las actividades académicas establecidas, desarrollando su formación en la jornada nocturna, bajo la modalidad a distancia, en una institución de carácter privado, calendario A.  
\nAsimismo, se certifica que el proceso de matrícula se realizó sin costo alguno ($0 pesos M/CTE) y que las mensualidades correspondientes tuvieron un valor de $[VALOR MENSUALIDAD] pesos M/CTE.  
\n\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia de graduación y pensión',
  },
  constanciaDeGraduacionPensionyPromedio: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], finalizó satisfactoriamente el Ciclo VI (11º) del Bachillerato Académico en nuestra institución, obteniendo su grado el [FECHA DE GRADUACIÓN].  
\nEl estudiante cumplió con la totalidad de las actividades académicas establecidas, cursando sus estudios en el calendario A, en la jornada nocturna, bajo la modalidad a distancia, en una institución de carácter privado.  
\nAsimismo, se certifica que su mensualidad tuvo un valor de $[VALOR MENSUALIDAD] pesos M/CTE y que, tras su desempeño académico, ocupó el puesto [PUESTO] entre [TOTAL ALUMNOS] alumnos, con un promedio final de [PROMEDIO FINAL], correspondiente a un nivel de desempeño [NIVEL DE DESEMPEÑO].  

La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: `Constancia de graduacion, pension y promedio`,
  },
  constanciaDeAnual: {
    text: `Que, [NOMBRE DEL ESTUDIANTE], identificado(a) con [TIPO DE DOCUMENTO] No. [NÚMERO DE DOCUMENTO], expedido en [LUGAR DE EXPEDICIÓN], se encuentra cursando el Grado [GRADO] del nivel [NIVEL EDUCATIVO], correspondiente al período lectivo del año [AÑO].  
\nEl/la estudiante desarrolla su formación cumpliendo con todas las actividades académicas establecidas, con una intensidad horaria de [INTENSIDAD HORARIA] horas semanales.  
\n
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia Anual',
  },

  constanciaPrincipalTechnical: {
    text: `Que, el/la estudiante [NOMBRE DEL ESTUDIANTE], identificado(a) con la [TIPO DE DOCUMENTO] No. [NÚMERO], expedido en [LUGAR DE EXPEDICIÓN], se encuentra cursando el primer semestre del programa Técnico Laboral en Seguridad Ocupacional y Laboral.  
Este programa, aprobado mediante la Resolución No. [NÚMERO DE RESOLUCIÓN] del [FECHA DE RESOLUCIÓN], dio inicio el [FECHA DE INICIO] y culminará el [FECHA DE FINALIZACIÓN].  
El plan de estudios contempla una intensidad horaria de [INTENSIDAD HORARIA] horas semanales, en la jornada [JORNADA], en la cual el/la estudiante cumple satisfactoriamente con todas las actividades y requisitos académicos establecidos.  

La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia Principal - Técnicas',
  },
  constanciaEspecial: {
    text: `Que el/la estudiante [NOMBRE DEL ESTUDIANTE], identificado(a) con la [TIPO DE DOCUMENTO] No. [NÚMERO], expedido(a) en [LUGAR DE EXPEDICIÓN], se encuentra matriculado(a) en el segundo semestre del programa Técnico Laboral en Auxiliar de Atención a la Primera Infancia, el cual se rige bajo la Resolución No. [NÚMERO DE RESOLUCIÓN] de fecha [FECHA DE RESOLUCIÓN].  
Este programa tiene una intensidad horaria de [INTENSIDAD HORARIA] horas semanales y el/la estudiante se encuentra cumpliendo cabalmente con todas las actividades y requisitos establecidos.  

Detalles del Programa: Resolución del Programa: No. [NÚMERO DE RESOLUCIÓN], expedida el [FECHA DE RESOLUCIÓN]. Licencia de Funcionamiento: No. [NÚMERO DE LICENCIA], expedida el [FECHA DE LICENCIA]. Nivel Académico: Técnico Laboral en Auxiliar de Educación para la Primera Infancia. Duración del Programa: [DURACIÓN] meses. Modalidad: [MODALIDAD]. Fecha de Inicio: [FECHA DE INICIO]. Fecha de Finalización: [FECHA DE FINALIZACIÓN].  

La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Constancia Especial - Técnicas',
  },
  cartaPasantias: {
    text: `APROBADO POR LA SECRETARÍA DE EDUCACIÓN DE BOLÍVAR SEGÚN LICENCIA DE FUNCIONAMIENTO No. 3084 DEL 30 DE DICIEMBRE DEL AÑO 2020, EN SU EDUCACIÓN DE PROGRAMAS TÉCNICOS LABORALES.  
NIT No. 901186633-9  

Asunto: SOLICITUD DE PRÁCTICAS O PASANTÍAS
Cordial saludo,  

El INSTITUTO TÉCNICO IBEROAMERICANO, dentro del marco de su programa de formación para el trabajo y desarrollo humano, se dedica a la certificación de programas educativos técnicos laborales. En tal sentido, nos permitimos presentar al(a) estudiante [NOMBRE DEL ESTUDIANTE], identificado(a) con el [TIPO DE DOCUMENTO] No. [NÚMERO], expedido(a) en [LUGAR DE EXPEDICIÓN], quien actualmente cursa la Técnica Laboral en Auxiliar de Educación para la Primera Infancia, con Resolución No. [NÚMERO DE RESOLUCIÓN] de [AÑO DE RESOLUCIÓN].  
Como parte del cumplimiento de los requisitos para su formación académica, los estudiantes de nuestro programa desarrollan un plan de ayuda empresarial, el cual constituye un medio fundamental de aprendizaje y su primer contacto en el ejercicio profesional. Es por ello que solicitamos la autorización para que el/la estudiante realice con ustedes las prácticas o pasantías requeridas, las cuales le permitirán complementar su formación mediante la ejecución de funciones concretas relacionadas con su área de estudio.  

Este proceso tiene como objetivo aplicar los conocimientos teóricos y prácticos adquiridos durante su formación académica en las diversas actividades correspondientes a su programa.  

Nota Aclaratoria:
El INSTITUTO TÉCNICO IBEROAMERICANO no se hace responsable de ningún incidente o accidente que pudiera ocurrir durante la etapa de prácticas o pasantías. Asimismo, el instituto no incluirá ni cubrirá el seguro ARL, siendo responsabilidad de la entidad receptora o del mismo pasante gestionar y asumir dicha cobertura.  

Agradecemos de antemano su colaboración y quedamos atentos a su respuesta.  
La presente constancia se expide a solicitud del/de la interesado(a) el día [FECHA DE EXPEDICIÓN], para los fines legales y administrativos a que haya lugar.`,
    name: 'Carta Pasantías',
  },
  constanciaEspanol: {
    text: `KLAZIA INTERNATIONAL SCHOOL  
FLDOE #7240  

848 Brickell Ave; Suite 950  
Miami, Fl 33131  

CERTIFICADO DE MATRÍCULA

La presente tiene como finalidad certificar que [NOMBRE DEL ESTUDIANTE] se encuentra matriculado(a) oficialmente en el programa de [Elementary, Middle o High] School, en el grado [GRADO], con código de alumno [CÓDIGO DE ALUMNO], en esta institución, durante el año escolar 2025.  

La presente certificación se expide a solicitud del(a) interesado(a) [NOMBRE DEL ACUDIENTE], quien se identifica con [TIPO DE DOCUMENTO] No. [NÚMERO], de [LUGAR DE EXPEDICIÓN], en calidad de acudiente del menor, para los efectos legales que correspondan conforme a la normativa vigente.  

Dado en Klazia International School, Miami, Florida, a los [DÍA] días del mes de enero de 2025.`,
    name: 'Constancia High School - Español',
  },
  constanciaIngles: {
    text: `KLAZIA INTERNATIONAL SCHOOL  
FLDOE #7240  

848 Brickell Ave; Suite 950  
Miami, Fl 33131  

CERTIFICATE OF REGISTRATION 

This is to certify that [NOMBRE DEL ESTUDIANTE] is officially enrolled in the [GRADO] grade of the [Elementary, Middle, or High] School program, with student code [CÓDIGO DE ALUMNO], at this institution for the 2025 school year.  

This certification is issued at the request of the interested party, [NOMBRE ACUDIENTE], identified with [TIPO DE DOCUMENTO] No. [NÚMERO], of [LUGAR DE EXPEDICIÓN], guardian of the minor, for any legal purposes it may serve in accordance with applicable laws.  

Given this [DÍA] day of [MES], 2025, at Klazia International School, Miami, Florida.`,
    name: 'Constancia High School - Inglés',
  },
  recommendation: {
    text: `MIAMI, (February 03, 2023).

[Nombre de la Universidad] University

Recommendation letter for [NOMBRE DEL ESTUDIANTE]

To whom it may concern:

I have had the pleasure of knowing [NOMBRE DEL ESTUDIANTE] very closely during the last year, in the academic field as well as personally. I was his/her President, and I can responsibly tell you that [he/she] is one of the most mature, committed, and dedicated students of his/her course.

[He/she] had an excellent academic performance, standing out among the first five of his/her class. His/her qualifications in [Algebra, Social Sciences, and Chemistry]. [He/she] also possesses extraordinary skills in communication, personal relations, and teamwork.

In a personal aspect, the qualities I admire from [him/her] are constancy and honesty; it is also evident [his/her] marked persistence, a healthy competition, and a clear goal for obtaining his/her objectives. [He/she] has a lot of confidence in the future and I think [he/she] will achieve anything [he/she] proposes.

I consider that [NOMBRE DEL ESTUDIANTE] is prepared for taking superior level studies in a well-recognized international academic institution and this is why I highly recommend [him/her].

If you need further information regarding this reference, it will be my pleasure to assist you.

Respectfully,`,
    name: 'Carta de recomendación',
  },
};

const instituciones = [
  {
    name: 'Instituto Iberoamericano CLEI',
    type: 'institutoib',
    header: `El suscrito Rector del Instituto Iberoamericano identificado con el código del DANE no 313836800158, Nit no. 901186633-9, código ICFES (instituto colombiano para la evaluación de la educación) no. 723775, 732917, aprobado por la Secretaría de Educación de Bolívar según Resolución no. 0438 del 11 de febrero del 2019, Resolución 3556 del 29 de noviembre del 2022 y Actualizadas según Resolución 3044 y autorizado para ofrecer educación formal de bachillerato por Ciclos Lectivos especiales Integrados (CLEI).`,
  },
  {
    name: 'Instituto Iberoamericano ANUAL',
    header: `El suscrito Rector del Instituto Iberoamericano identificado con el código del DANE no 313836800158, Nit no. 901186633-9, aprobado por la Secretaria de Educación de Bolívar según Resolución no. 0438 del 11 de febrero del 2019, Resolución 3556 del 29 de noviembre del 2022 y Actualizadas según Resolución 3044.`,
  },
  {
    name: 'Colegio Iberoamericano',
    header: `El suscrito director del Colegio Iberoamericano, identificado con el código del DANE no 368001007723, Nit no. 5591281-6, código del ICFES (instituto colombiano para el fomento de la educación superior) no. 097808 y aprobado por la secretaria de educación de Santander según Resolución no. 12219 del 17 de julio del 2000, en su educación formal de bachillerato por ciclos lectivos especiales integrados (CLEI).`,
  },
  {
    name: 'Colegio Internacional los Andes',
    header: `El suscrito Rector del Colegio Internacional Los Andes identificado con el código del DANE no 313052800144, Nit no. 901186633-9, código ICFES (instituto colombiano para la evaluación de la educación) no. 818187 y 818203, aprobado por la Secretaría de Educación de Bolívar según Resolución no. 2933 del 29 de diciembre del 2023 y autorizado para ofrecer educación formal de bachillerato por Ciclos Lectivos especiales Integrados (CLEI).`,
  },
  {
    name: 'Instituto Técnico Iberoamericano',
    type: 'technical',
    header:
      'El suscrito Director del Instituto Técnico Iberoamericano identificado con el Nit no. 901186633-9, aprobado por la Secretaría de Educación de Bolívar según Licencia de Funcionamiento no. 3084 emitida el 30 de diciembre del año 2020, y autorizado para ofrecer educación formal de programas técnicos laborales.',
  },
  { name: 'Klazia International School', header: 'KLAZIA INTERNATIONAL SCHOOL', type: 'highschool' },
];

const tiposDocumento = [
  { value: 'CC', label: 'Cédula de Ciudadanía' },
  { value: 'TI', label: 'Tarjeta de Identidad' },
  { value: 'CE', label: 'Cédula de Extranjería' },
  { value: 'PA', label: 'Pasaporte' },
  { value: 'RC', label: 'Registro Civil' },
];

export default function ConstanciasYCertificados() {
  const params = useParams();
  const idEst = params.idEst;

  const [certificateType, setCertificateType] = useState('constanciaPrincipal');
  const [customText, setCustomText] = useState('');
  const [headerText, setHeaderText] = useState(
    'El suscrito Rector del Instituto Iberoamericano identificado con el código del DANE no 313836800158, Nit no. 901186633-9, código ICFES (instituto colombiano para la evaluación de la educación) no. 723775, 732917, aprobado por la Secretaría de Educación de Bolívar según Resolución no. 0438 del 11 de febrero del 2019, Resolución 3556 del 29 de noviembre del 2022 y Actualizadas según Resolución 3044 y autorizado para ofrecer educación formal de bachillerato por Ciclos Lectivos especiales Integrados (CLEI).'
  );
  const [studentName, setStudentName] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [expedition, setExpedition] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [institution, setInstitution] = useState('Instituto Iberoamericano CLEI');
  const [institutionType, setInstitutionType] = useState('institutoib');
  const [certificateName, setCertificateName] = useState('Constancia Principal');

  const [loading, setLoading] = useState(false);
  const [additionalBoldWords, setAdditionalBoldWords] = useState([
    'Instituto Iberoamericano',
    'DANE',
    '313836800158',
    'Nit',
    '901186633-9',
    'ICFES',
    '723775',
    '732917',
    'Resolución 3044',
    '313052800144',
    '818187',
    '818203',
    '368001007723',
    '5591281-6',
    '097808',
  ]);
  const toast = useToast();

  useEffect(() => {
    updateCustomText();
  }, [certificateType, studentName, documentType, expedition, documentNumber, institution]);

  const handleCertificateTypeChange = (value) => {
    setCertificateType(value);
    setCertificateName(certificateTypes[value]?.name);
    updateCustomText(value);
  };

  console.log('certificateName', certificateName);

  const updateCustomText = (newCertificateType = certificateType) => {
    let texto = certificateTypes[newCertificateType] || '';
    let newText = texto.text;
    const tipoDocumentoLabel =
      tiposDocumento.find((tipo) => tipo.value === documentType)?.label || '[TIPO DE DOCUMENTO]';
    newText = newText
      .replace('[NOMBRE DEL ESTUDIANTE]', studentName || '[NOMBRE DEL ESTUDIANTE]')
      .replace('[TIPO DE DOCUMENTO]', tipoDocumentoLabel)
      .replace('[NÚMERO DE DOCUMENTO]', documentNumber || '[NÚMERO DE DOCUMENTO]')
      .replace('[LUGAR DE EXPEDICIÓN]', expedition || '[LUGAR DE EXPEDICIÓN]')
      .replace('[INSTITUCION]', institution || '[INSTITUCION]');
    setCustomText(newText);
  };

  const handleCertificate = () => {
    setLoading(true);
    setTimeout(() => {
      generarCertificado();
    }, 1000);
  };

  const generarCertificado = async () => {
    try {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [2000, 1414],
        filters: ['ASCIIHexEncode'],
      });

      doc.setProperties({
        title: 'Certificado Personalizado',
        author: institution,
        creator: 'Sistema de Certificados',
      });

      let backgroundImage = '/1.webp';
      if (institution === 'Instituto Iberoamericano CLEI') {
        backgroundImage = '/2.webp';
      } else if (institution === 'Instituto Iberoamericano ANUAL') {
        backgroundImage = '/2.webp';
      } else if (institution === 'Colegio Iberoamericano') {
        backgroundImage = '/5.webp';
      } else if (institution === 'Instituto Técnico Iberoamericano') {
        backgroundImage = '/1.webp';
      } else if (institution === 'Colegio Internacional los Andes') {
        backgroundImage = '/4.webp';
      } else if (institution === 'Klazia International School') {
        backgroundImage = '/3.webp';
      }

      doc.addImage(backgroundImage, 'JPEG', 0, 0, 1414, 2000);

      // Set up regular font first
      doc.setFont('Helvetica', 'normal');
      if (certificateName === 'Constancia de no registro al SIMAT') {
        doc.setFontSize(26);
      } else if (certificateName === 'Carta Pasantías') {
        doc.setFontSize(26);
      } else {
        doc.setFontSize(29);
      }

      doc.setTextColor(0, 0, 0);

      // Function to process text and make form inputs bold
      const processTextWithBold = (text, startY, maxWidth) => {
        const lines = doc.splitTextToSize(text, maxWidth);
        let currentY = startY;

        lines.forEach((line) => {
          // Skip justification for short lines or last lines of paragraphs
          const shouldJustify = line.length > 50 && !line.endsWith('.');

          const segments = [];
          let lastIndex = 0;

          // Find all form input values and additional bold words in the text
          const boldItems = [
            studentName,
            documentType && tiposDocumento.find((tipo) => tipo.value === documentType)?.label,
            documentNumber,
            institution,
            expedition,
            ...additionalBoldWords,
            'DANE',
            'ICFES',
            'Nit',
          ].filter(Boolean);

          // Create segments alternating between normal and bold text
          boldItems.forEach((item) => {
            if (item && line.includes(item)) {
              const index = line.indexOf(item, lastIndex);
              if (index !== -1) {
                // Add normal text before the bold item
                if (index > lastIndex) {
                  segments.push({
                    text: line.substring(lastIndex, index),
                    isBold: false,
                  });
                }
                // Add the bold item
                segments.push({
                  text: item,
                  isBold: true,
                });
                lastIndex = index + item.length;
              }
            }
          });

          // Add any remaining text after the last bold item
          if (lastIndex < line.length) {
            segments.push({
              text: line.substring(lastIndex),
              isBold: false,
            });
          }

          // Calculate total width of text and spaces
          let totalWidth = 0;
          let spaceCount = 0;

          segments.forEach((segment) => {
            doc.setFont('Helvetica', segment.isBold ? 'bold' : 'normal');
            const words = segment.text.split(' ');
            words.forEach((word) => {
              totalWidth += doc.getTextWidth(word);
              if (word !== '') spaceCount++;
            });
          });

          // Calculate space between words for justification
          const normalSpaceWidth = doc.getTextWidth(' ');
          const totalSpaceNeeded = maxWidth - totalWidth;
          const extraSpacePerWord = shouldJustify
            ? (totalSpaceNeeded - spaceCount * normalSpaceWidth) / (spaceCount - 1)
            : 0;

          // Render text with calculated spacing
          let currentX;
          if (institution === 'Colegio Iberoamericano') {
            currentX = 240;
          } else {
            currentX = 140;
          }

          segments.forEach((segment, segmentIndex) => {
            doc.setFont('Helvetica', segment.isBold ? 'bold' : 'normal');
            const words = segment.text.split(' ');

            words.forEach((word, wordIndex) => {
              if (word !== '') {
                doc.text(word, currentX, currentY);
                currentX += doc.getTextWidth(word);

                // Add space after word (except for last word in line)
                if (wordIndex < words.length - 1 || segmentIndex < segments.length - 1) {
                  currentX += normalSpaceWidth + (shouldJustify ? extraSpacePerWord : 0);
                }
              }
            });
          });

          currentY += 40;
        });

        return currentY;
      };

      if (certificateType === 'cartaPasantias' || institutionType === 'highschool') {
        // Render main text
        processTextWithBold(customText, 430, 1150);
      } else {
        console.log('institutionType', institutionType);
        if (institutionType === 'institutoib') {
          if (certificateName === 'Constancia de no registro al SIMAT') {
            console.log('entreaqui1');
            // Render header text with justification and bold words
            processTextWithBold(headerText, 400, 1050);

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            doc.text('HACE CONSTAR', 650, 600, {
              align: 'center',
            });

            // Render main text
            processTextWithBold(customText, 650, 1050);
          } else {
            console.log('si1');
            // Render header text with justification and bold words
            processTextWithBold(headerText, 500, 1050);

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            doc.text('HACE CONSTAR', 707, 800, {
              align: 'center',
            });

            // Render main text
            processTextWithBold(customText, 900, 1050);
          }
        } else {
          if (institution === 'Colegio Iberoamericano') {
            console.log('entreaqui2 col ibero');
            // Render header text with justification and bold words
            processTextWithBold(headerText, 400, 1050);

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            doc.text('HACE CONSTAR', 700, 560, {
              align: 'center',
            });

            // Render main text
            processTextWithBold(customText, 600, 1050);
          } else if (institution === 'Colegio Internacional los Andes') {
            console.log('entreaqui3 andes');
            // Render header text with justification and bold words
            processTextWithBold(headerText, 330, 1050);

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            doc.text('HACE CONSTAR', 700, 500, {
              align: 'center',
            });

            // Render main text
            processTextWithBold(customText, 550, 1050);
          } else if (institution === 'Instituto Técnico Iberoamericano') {
            console.log('entreaqui3 ínstituto');
            // Render header text with justification and bold words
            if (certificateName !== 'Carta Pasantías') {
              processTextWithBold(headerText, 530, 1050);
            }

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            if (certificateName !== 'Carta Pasantías') {
              doc.text('HACE CONSTAR', 700, 710, {
                align: 'center',
              });
            }

            // Render main text
            processTextWithBold(customText, 800, 1050);
          } else {
            console.log('si123');
            // Render header text with justification and bold words
            processTextWithBold(headerText, 500, 1050);

            // Render "HACE CONSTAR"
            doc.setFont('Helvetica', 'bold');
            doc.text('HACE CONSTAR', 707, 800, {
              align: 'center',
            });

            // Render main text
            processTextWithBold(customText, 900, 1050);
          }
        }
      }

      const pdfBlob = doc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const file = new File([pdfBlob], `certificado-${new Date().getTime()}.pdf`, { type: 'application/pdf' });

      window.open(pdfUrl, '_blank');

      // const response = await xhr.post(`/student-documents`, {
      //   data: {
      //     student: idEst,
      //     type: certificateName,
      //     text: '',
      //     school: institution,
      //   },
      // });

      // await xhr.post(`/student-graduateds`, {
      //   data: {
      //     name: studentName,
      //     type_document: documentType,
      //     document: documentNumber,
      //     institution: institution,
      //     status: 'Aprobado',
      //     student_document: response.data.data.id,
      //   },
      // });

      // const formData = new FormData();

      // formData.append('files', file);
      // formData.append('ref', 'api::student-document.student-document');
      // formData.append('refId', response.data.data.id);
      // formData.append('field', 'file');

      // const res = await xhr('/upload', {
      //   method: 'post',
      //   data: formData,
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // });

      toast({
        title: 'Certificado generado',
        description: 'El certificado se ha generado correctamente.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error al generar el certificado:', error);
      toast({
        title: 'Error',
        description: 'Hubo un problema al generar el certificado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  console.log('institution', institution);

  return (
    <Box p={5}>
      <Link to={`/plataforma/administrador/ver-estudiante/${idEst}`}>
        <a>Volver</a>
      </Link>
      <Box mb={6} fontWeight={'semibold'} fontSize={20}>
        Generador de Constancias y Certificados
      </Box>
      <Box width={'100%'}>
        {institutionType === 'technical' ? (
          <RadioGroup onChange={handleCertificateTypeChange} value={certificateType} mb={6} width={'100%'}>
            <Stack direction="row" wrap="wrap" spacing={4} maxWidth="100%">
              <Radio value="constanciaPrincipalTechnical">Constancia Principal</Radio>
              <Radio value="constanciaEspecial">Constancia Especial</Radio>
              <Radio value="cartaPasantias">Carta Pasantías</Radio>
            </Stack>
          </RadioGroup>
        ) : institutionType === 'highschool' ? (
          <RadioGroup onChange={handleCertificateTypeChange} value={certificateType} mb={6} width={'100%'}>
            <Stack direction="row" wrap="wrap" spacing={4} maxWidth="100%">
              <Radio value="constanciaEspanol">Constancia Español</Radio>
              <Radio value="constanciaIngles">Constancia Inglés</Radio>
              <Radio value="recommendation">Recomendación universitaria</Radio>
            </Stack>
          </RadioGroup>
        ) : (
          <RadioGroup onChange={handleCertificateTypeChange} value={certificateType} mb={6} width={'100%'}>
            <Stack direction="row" wrap="wrap" spacing={4} maxWidth="100%">
              <Radio value="constanciaPrincipal">Constancia Principal</Radio>
              <Radio value="constanciaGradoProximo">Constancia Grado Proximo</Radio>
              <Radio value="constanciaDeMatriculaParaRetiroAnteriorColegio">
                Constancia de Matrícula Para Retiro Anterior Colegio
              </Radio>
              <Radio value="pazysalvo">Paz y Salvo</Radio>
              <Radio value="constanciaServicioSocial">Constancia Servicio Social</Radio>
              <Radio value="certificadoDeConducta">Certificado De Conducta</Radio>
              <Radio value="constanciaNoRegistroSIMAT">Constancia No Registro SIMAT</Radio>
              <Radio value="constanciaDeGraduacionyPension">Constancia De Graduación y Pensión</Radio>
              <Radio value="constanciaDeGraduacionPensionyPromedio">Constancia De Graduacion Pension y Promedio</Radio>
              <Radio value="constanciaDeAnual">Constancia De Anual</Radio>
            </Stack>
          </RadioGroup>
        )}
      </Box>

      <VStack spacing={5} align="stretch">
        <Flex gap={3}>
          <Input
            placeholder="Nombre del estudiante"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <Select
            placeholder="Tipo de documento"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
          >
            {tiposDocumento.map((tipo) => (
              <option key={tipo.value} value={tipo.value}>
                {tipo.label}
              </option>
            ))}
          </Select>
          <Input
            placeholder="Número de documento"
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
          <Input placeholder="Lugar de Expedición" value={expedition} onChange={(e) => setExpedition(e.target.value)} />
          <Select
            value={institution}
            onChange={(e) => {
              const selectedInstitution = instituciones.find((inst) => inst.name === e.target.value);
              setInstitution(selectedInstitution.name);
              setInstitutionType(selectedInstitution.type);
              setHeaderText(selectedInstitution.header);
            }}
          >
            {instituciones.map((inst, index) => (
              <option key={index} value={inst.name}>
                {inst.name}
              </option>
            ))}
          </Select>
        </Flex>
        <p>Palabras adicionales en negrita (separadas por coma)</p>
        <Input
          placeholder="Palabras adicionales en negrita (separadas por coma)"
          value={additionalBoldWords.join(', ')}
          onChange={(e) => setAdditionalBoldWords(e.target.value.split(',').map((word) => word.trim()))}
        />
        <h2>Cuerpo</h2>
        <Textarea
          value={customText}
          onChange={(e) => setCustomText(e.target.value)}
          placeholder="Seleccione un tipo de certificado o ingrese texto personalizado"
          size="lg"
          minHeight="200px"
        />
        <h2>Encabezado</h2>
        <Textarea
          value={headerText}
          onChange={(e) => setHeaderText(e.target.value)}
          placeholder="Ingrese el encabezado del certificado"
          size="lg"
          minHeight="100px"
        />
        <Button
          colorScheme="blue"
          onClick={() => {
            setLoading(true);
            handleCertificate();
          }}
          isLoading={loading}
          loadingText="Generando..."
          isDisabled={!customText || !institution || !studentName || !documentNumber || !documentType}
        >
          Generar Certificado
        </Button>
      </VStack>
    </Box>
  );
}
