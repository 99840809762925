import React, { Suspense } from 'react';
import {
  Grid,
  GridItem,
  Flex,
  Box,
  Button,
  Container,
  Select,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import Loading from 'components/loading/Loading';
import useSWR from 'swr';
import Icon from 'components/icon/Icon';

import UpdateScore from './updateScore';
import getResults from 'api/administrador/student/getResults';
import UpdateExtraScore from './extraNote';
import InsertNotes from './insertNotes';

import ModalFinal from './modalFinal';
import getUser from 'api/administrador/student/getUser';
import BoletinPeriodo from './boletinPeriodo/boletin';

function VerCurso() {
  const [openModal, setOpenModal] = React.useState(false);
  const [click, setClick] = React.useState(false);
  const [isFetching2, setIsFetching2] = React.useState('');
  const [subject, setSubject] = React.useState({});
  const [action, setAction] = React.useState('');
  const [idActivity, setIdActivity] = React.useState('');
  const [type, setType] = React.useState('');
  const [enrollment, setEnrollment] = React.useState({});

  const [material, setMaterial] = React.useState([]);

  const params = useParams();
  let [searchParams] = useSearchParams();
  const id_est = searchParams.get('id');

  const { data: userStudent } = useSWR({ id_est, type: 'getInfoUserAdmin' }, getUser, {
    revalidateOnFocus: false,
  });

  const student = userStudent ? userStudent[0] : undefined;

  const { data, mutate } = useSWR(
    {
      id_est: id_est,
      type: params.type,
      program: params.program,
      id: searchParams.get('id'),
    },
    getResults,
    {
      revalidateOnFocus: false,
    }
  );

  const handlePeriod = (event) => {
    mutate(
      {
        ...data,
        resultsData: data.periodResults[event.target.value],
        currentPeriod: event.target.value,
      },
      false
    );
  };

  if (!data) return <Loading />;
  const { currentPeriod, periodResults, programType, resultsData } = data;

  const ifBoleting =
    params.type === 'bachillerato-anual-para-adolescentes' ||
    params.type === 'primaria-anual-para-ninos' ||
    params.type === 'bachillerato-flexible';

  return (
    <Suspense fallback={<Loading />}>
      <Link to={`/plataforma/administrador/ver-estudiante/${id_est}`}>
        <Button colorScheme="purple" variant="outline">
          Atrás
        </Button>
      </Link>
      <Grid marginLeft="0px" marginRight="0px">
        <GridItem colSpan={9}>
          <Container paddingY="15px" minHeight="70vh" paddingX="10px" maxWidth="1000px">
            <Box fontWeight="bold" fontSize="35px" marginBottom="15px">
              RESULTADOS DEL PROGRAMA
            </Box>

            {(programType.slug === 'bachillerato-anual-para-adolescentes' ||
              programType.slug === 'primaria-anual-para-ninos' ||
              programType.slug === 'bachillerato-flexible') && (
              <Flex>
                <Select value={currentPeriod} width="220px" marginBottom="15px" onChange={handlePeriod}>
                  {Object.keys(periodResults)
                    .sort()
                    .map((el) => {
                      return (
                        <option value={el} key={el}>
                          {el} periodo
                        </option>
                      );
                    })}
                </Select>

                <InsertNotes
                  currentPeriod={currentPeriod}
                  id_est={id_est}
                  idGrade={resultsData[0].subject[programType.type]?.id}
                  currentNotesPeriod={periodResults[currentPeriod]}
                  results={resultsData}
                />
              </Flex>
            )}
            <ModalFinal
              idEst={id_est}
              idGrade={resultsData[0].subject?.grade?.id}
              student={student}
              resultsData={resultsData}
            />
            {ifBoleting && (
              <BoletinPeriodo
                student={student}
                dataResults={periodResults || resultsData}
                type={resultsData[0].subject?.grade ? 'grade' : 'technical_career'}
                period={currentPeriod}
                dateBoletin={resultsData[0].subject?.boletin_date}
              />
            )}
            {/* Modal para agregar nota */}

            <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  {type === 'extraNote' || type === 'extraNoteFinal' ? (
                    <UpdateExtraScore
                      action={action}
                      material={material}
                      id_est={id_est}
                      type={type}
                      dataMutate={{
                        id_est: id_est,
                        type: params.type,
                        program: params.program,
                        id: searchParams.get('id'),
                      }}
                      subject={subject}
                      idActivity={idActivity}
                      setIsFetching2={setIsFetching2}
                      setClick={setClick}
                      setOpenModal={setOpenModal}
                      click={click}
                      setEnrollment={setEnrollment}
                      enrollment={enrollment}
                    />
                  ) : (
                    <UpdateScore
                      setOpenModal={setOpenModal}
                      action={action}
                      mutate={mutate}
                      dataMutate={{
                        id_est: id_est,
                        type: params.type,
                        program: params.program,
                        id: searchParams.get('id'),
                      }}
                      material={material}
                      id_est={id_est}
                      type={type}
                      subject={subject}
                      idActivity={idActivity}
                      setIsFetching2={setIsFetching2}
                      setClick={setClick}
                      click={click}
                    />
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            {resultsData.map((result) => {
              return (
                <Box key={result.id} marginBottom="15px">
                  <Flex alignItems="center">
                    {result.subject.image && <Image src={result.subject.image.url} width="42px" height="42px" />}

                    <Box marginLeft="0px">
                      <Flex alignItems="center">
                        <Box fontSize="29px" fontWeight="500" margin="0">
                          {result.subject.name}
                        </Box>

                        {result.subject.is_extra_sub ? (
                          <Box fontSize="15px" color="blue" marginLeft="10px">
                            <b>(Materia Extra)</b>
                          </Box>
                        ) : (
                          ''
                        )}

                        {result.no_enrollment && (
                          <Box color="red" marginLeft="7px" fontSize="14px" fontWeight="500">
                            (Sin matricular)
                          </Box>
                        )}
                      </Flex>
                      <Box color="text100">
                        Completado: {result.final_score.completed ? result.final_score.completed : 0}%
                      </Box>
                    </Box>
                  </Flex>

                  <Box
                    boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 #574feb"
                    paddingX="15px"
                    paddingY="10px"
                    borderRadius="8px"
                    marginY="10px"
                  >
                    <Flex justifyContent="space-between" paddingY="7px">
                      <Box>
                        <Collapsible
                          trigger={
                            <Box cursor="pointer">
                              <Flex alignItems="center">
                                <Box fontWeight="500" marginRight="minor-1">
                                  Actividades (40%)
                                </Box>
                                <Icon name="Chevron" fontSize={10} color="gray" />
                              </Flex>
                              <Box fontSize="14px" color="text100">
                                Completado {result.activities.completed} de {result.activities.total}
                              </Box>
                            </Box>
                          }
                          transitionTime={200}
                        >
                          <Box marginTop="7px">
                            {result.activitiesByModule &&
                              result.activitiesByModule.map((el) => {
                                if (!el.activities.length) return null;

                                return (
                                  <Box key={el.module}>
                                    <Box fontWeight="500">{el.module}</Box>

                                    {el.activities.map((act) => {
                                      return (
                                        <Flex alignItems="center" marginY="7px" key={act.title}>
                                          <Box maxWidth="200px" minWidth="200px">
                                            {act.title}
                                          </Box>
                                          <Box marginLeft="15px" display="flex">
                                            {isFetching2 === act.id ? (
                                              <Spinner size="small" />
                                            ) : act.score !== null ? (
                                              <>
                                                {act.score.toFixed(2)}{' '}
                                                <Box marginLeft="20px" marginRight="10px">
                                                  <Button
                                                    colorScheme="gray"
                                                    variant="outline"
                                                    padding="10px"
                                                    minHeight="10px"
                                                    height="20px"
                                                    fontSize="14px"
                                                    onClick={() => {
                                                      setMaterial(act);
                                                      setType('activity');
                                                      setAction('edit');
                                                      setIdActivity(act.id);
                                                      setClick(true);
                                                      setOpenModal(true);
                                                    }}
                                                  >
                                                    Editar
                                                  </Button>
                                                </Box>
                                              </>
                                            ) : (
                                              <>
                                                {' '}
                                                Pendiente{' '}
                                                <Box marginLeft="20px" marginRight="10px">
                                                  <Button
                                                    colorScheme="gray"
                                                    variant="outline"
                                                    padding="10px"
                                                    minHeight="10px"
                                                    height="20px"
                                                    fontSize="14px"
                                                    onClick={() => {
                                                      setMaterial(act);
                                                      setType('activity');
                                                      setAction('add');
                                                      setSubject(result.subject);
                                                      setIdActivity(act.id);
                                                      setClick(true);
                                                      setOpenModal(true);
                                                    }}
                                                  >
                                                    Agregar nota
                                                  </Button>
                                                </Box>{' '}
                                              </>
                                            )}
                                          </Box>
                                        </Flex>
                                      );
                                    })}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Collapsible>
                      </Box>

                      <Box textAlign="center">
                        <Box fontWeight="500">{result.activities.score ? result.activities.score : 0}</Box>
                        <Box fontSize="14px">PROMEDIO</Box>
                      </Box>
                    </Flex>

                    <Flex justifyContent="space-between" paddingY="7px">
                      <Box>
                        <Collapsible
                          trigger={
                            <Box cursor="pointer">
                              <Flex alignItems="center">
                                <Box fontWeight="500" marginRight="minor-1">
                                  Quices (30%)
                                </Box>
                                <Icon name="Chevron" fontSize={10} color="gray" />
                              </Flex>
                              <Box fontSize="14px" color="text100">
                                Completado {result.quizzes.completed} de {result.quizzes.total}
                              </Box>
                            </Box>
                          }
                          transitionTime={200}
                        >
                          <Box marginTop="7px">
                            {result.quizzesByModule &&
                              result.quizzesByModule.map((el) => {
                                if (!el.quizzes.length) return null;

                                return (
                                  <Box key={el.module}>
                                    <Box fontWeight="500">{el.module}</Box>

                                    {el.quizzes.map((quiz) => {
                                      return (
                                        <Flex alignItems="center" marginY="7px" key={quiz.title}>
                                          <Box maxWidth="200px" minWidth="200px">
                                            {quiz.title}
                                          </Box>
                                          <Box marginLeft="15px" display="flex">
                                            {quiz.score !== null ? (
                                              <>
                                                {' '}
                                                {quiz.score.toFixed(2)}{' '}
                                                <Box marginLeft="20px" marginRight="10px">
                                                  <Button
                                                    colorScheme="gray"
                                                    variant="outline"
                                                    padding="10px"
                                                    minHeight="10px"
                                                    height="20px"
                                                    fontSize="14px"
                                                    onClick={() => {
                                                      setMaterial(quiz);
                                                      setType('quiz');
                                                      setClick(true);
                                                      setOpenModal(true);
                                                    }}
                                                  >
                                                    Editar
                                                  </Button>
                                                </Box>{' '}
                                              </>
                                            ) : (
                                              <>
                                                Pendiente{' '}
                                                <Box marginLeft="20px" marginRight="10px">
                                                  <Button
                                                    colorScheme="gray"
                                                    variant="outline"
                                                    padding="10px"
                                                    minHeight="10px"
                                                    height="20px"
                                                    fontSize="14px"
                                                    onClick={() => {
                                                      setMaterial(quiz);
                                                      setType('quiz');
                                                      setAction('add');
                                                      setClick(true);
                                                      setOpenModal(true);
                                                    }}
                                                  >
                                                    Agregar Nota
                                                  </Button>
                                                </Box>{' '}
                                              </>
                                            )}
                                          </Box>
                                        </Flex>
                                      );
                                    })}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Collapsible>
                      </Box>

                      <Box textAlign="center">
                        <Box fontWeight="500">{result.quizzes.score}</Box>
                        <Box fontSize="14px">PROMEDIO</Box>
                      </Box>
                    </Flex>

                    <Flex alignItems="center" justifyContent="space-between" paddingY="7px">
                      <Box>
                        <Box fontWeight="500">Exámen Final (30%)</Box>
                        <Box fontSize="14px" color="text100">
                          {result.exam.completed ? (
                            <Flex>
                              Completado{' '}
                              <Box marginLeft="20px" marginRight="10px">
                                <Button
                                  colorScheme="gray"
                                  variant="outline"
                                  padding="10px"
                                  minHeight="10px"
                                  height="20px"
                                  fontSize="14px"
                                  onClick={() => {
                                    setMaterial(result.exam);
                                    setType('exam');
                                    setClick(true);
                                    setSubject(result.subject);
                                    setOpenModal(true);
                                  }}
                                >
                                  Editar
                                </Button>
                              </Box>{' '}
                            </Flex>
                          ) : (
                            <>
                              Pendiente{' '}
                              <Box marginLeft="20px" marginRight="10px">
                                <Button
                                  colorScheme="gray"
                                  variant="outline"
                                  padding="10px"
                                  minHeight="10px"
                                  height="20px"
                                  fontSize="14px"
                                  onClick={() => {
                                    setMaterial(result.exam);
                                    setType('exam');
                                    setSubject(result.subject);
                                    setAction('add');
                                    setClick(true);
                                    setOpenModal(true);
                                  }}
                                >
                                  Agregar Nota
                                </Button>
                              </Box>{' '}
                            </>
                          )}
                        </Box>
                      </Box>

                      <Box textAlign="center">
                        <Box fontWeight="500">{result.exam.score}</Box>
                        <Box fontSize="14px">RESULTADO</Box>
                      </Box>
                    </Flex>

                    <Flex alignItems="center" justifyContent="space-between" paddingY="7px">
                      <Box>
                        <Box fontWeight="500">Nota Final de la Materia (100%)</Box>
                        <Box fontSize="14px" color="text100">
                          Completado al {result.final_score.completed}%
                        </Box>
                      </Box>

                      <Box textAlign="center">
                        <Box fontWeight="600">
                          {result.recuperationQuizEnrollment
                            ? (Number(result.recuperationQuizEnrollment?.note) / 10).toFixed(1) <
                              result.final_score.score
                              ? result.final_score.score
                              : (result.recuperationQuizEnrollment?.note / 10).toFixed(1)
                            : enrollment?.extra_score
                            ? enrollment?.extra_score
                            : result.enrollment?.extra_final_note
                            ? result.enrollment?.extra_final_note.toFixed(2)
                            : result.final_score.score}
                        </Box>
                        <Box fontSize="14px">RESULTADO</Box>
                      </Box>
                    </Flex>

                    <Box fontSize="14px" color="text100">
                      <Flex>
                        <Box marginLeft="0px" marginRight="10px">
                          {result.recuperationQuizEnrollment ? (
                            <>
                              Nota de recuperación: <b>{(result.recuperationQuizEnrollment?.note / 10).toFixed(1)}</b>{' '}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          Nota final complementada: {result.enrollment?.extra_score}
                          <Button
                            padding="5px"
                            minHeight="10px"
                            height="20px"
                            fontSize="14px"
                            marginLeft="10px"
                            onClick={() => {
                              setSubject(result.subject);
                              setEnrollment(result.enrollment);
                              setType('extraNote');
                              setOpenModal(true);
                            }}
                          >
                            Agregar
                          </Button>
                        </Box>
                      </Flex>
                    </Box>

                    <Box fontSize="14px" color="text100">
                      <Flex>
                        <Box marginLeft="0px" marginRight="10px">
                          Nota final materia: {result.enrollment?.extra_final_note}
                          <Button
                            padding="5px"
                            minHeight="10px"
                            height="20px"
                            fontSize="14px"
                            marginLeft="10px"
                            onClick={() => {
                              setSubject(result.subject);
                              setEnrollment(result.enrollment);
                              setType('extraNoteFinal');
                              setOpenModal(true);
                            }}
                          >
                            Agregar
                          </Button>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Container>
        </GridItem>
      </Grid>
    </Suspense>
  );
}

export default VerCurso;
