'use client';

import jsPDF from 'jspdf';
import { useState } from 'react';

import { capitalize } from './capitalize';
import dayjs from 'dayjs';
import { Button } from '@chakra-ui/react';

function BoletinPeriodo({ student, dataResults, type, period, dateBoletin, isOnDay }) {
  if (type === 'technical_career') return null;
  const [loading, setLoading] = useState(false);

  let totalPuntos = 0;
  let totalItems = 0;

  const results = dataResults;

  const periodData = results[period];
  // Iterar sobre cada elemento en results
  if (periodData) {
    periodData.forEach((item) => {
      const score = item.enrollment?.extra_final_note
        ? item.enrollment?.extra_final_note
        : item.enrollment?.extra_score
        ? item.enrollment?.extra_score
        : item.recuperationQuizEnrollment
        ? item.recuperationQuizEnrollment.note / 10
        : parseFloat(item.final_score.score);
      totalPuntos += score;
      totalItems++;
    });
  }

  // Calcular el promedio
  const promedio = totalPuntos / totalItems;

  const generarCertificado = async () => {
    // Crea un nuevo documento PDF
    try {
      setTimeout(() => {
        const sinGrado = dataResults[period][0]?.subject[type]?.name.replace(/grado: /i, '');
        const soloText = sinGrado.split(' ')[0];

        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [2000, 1414],
          filters: ['ASCIIHexEncode'],
        });
        doc.setProperties({
          title: `Boletin - `,
          author: 'Klazia Corp',
          creator: 'Klazia Corp',
        }); // Establece el título del PDF

        doc.addImage(
          `${
            student?.school === 'san bartolome'
              ? `/boletin1.webp`
              : student?.school === 'nueva generacion'
              ? '/boletin_generacion.webp'
              : student?.school === 'iberoamericano'
              ? '/boletin_iberoamericano.webp'
              : student?.school === 'inecol'
              ? '/boletin_enecol.webp'
              : `/boletin_sanagustin.webp`
          }`,
          'xJPEG',
          0,
          0,
          1414,
          2000
        );

        const nombreEstudiante = 'Jhon'; // Tu variable con el nombre del estudiante

        doc.setFontSize(31);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${student?.user?.first_name} ${student?.user?.last_name}`, 380, 420);
        doc.text(`${student?.user.id}`, 1050, 420);
        doc.text(`${soloText}`, 1260, 420);

        ////2 linea
        doc.text(
          `${period === 'primer' ? '1' : period === 'segundo' ? '2' : period === 'tercer' ? '3' : '4'}`,
          190,
          480
        );
        doc.text(`${dateBoletin ? dayjs(dateBoletin).format('YYYY MM DD') : '2024 06 21'}`, 400, 480);
        doc.text(`Mañana`, 900, 480);

        doc.setFontSize(29);

        const init = 730;

        ////3 linea (Materias) se le suma 55 por linea
        dataResults[period].forEach((el, index) => {
          // Divide el texto en función de la palabra "periodo" sin importar mayúsculas/minúsculas
          let partes = el.subject.name.split(/periodo/i);

          const regex = /art[íi]stica/i;

          const nombreLimpio = partes[0]
            .replace(/\d+-\d+°/, '') // Elimina formatos como "2023-2024°"
            .replace(/\[\d+°\]\s*\d*/, '') // Elimina "[9°] 4" o similares
            .trim();

          // La primera parte es el texto que queremos
          let textoLimpio = nombreLimpio;

          const yOffset = index < 5 ? 55 : 53; // Usar 55 para las primeras 5 iteraciones, luego 40
          const getActivityPercentage = Number(el.activities.score) * 0.4;
          const getQuizPercentage = el.quizzes.score * 0.3;
          const getExamPercentage = el.exam.score * 0.3;
          const finalNote = el.enrollment?.extra_final_note
            ? el.enrollment?.extra_final_note
            : el.enrollment?.extra_score
            ? el.enrollment?.extra_score
            : el?.final_score
            ? parseFloat(el?.final_score?.score)
            : parseFloat('0.0');
          doc.setFontSize(22);
          doc.text(`${textoLimpio}`, 43, init + yOffset * index);

          doc.setFontSize(29);
          doc.text(`${Number(el.activities.score)}`, 400, init + yOffset * index, {
            align: 'center',
          });
          doc.text(`${getActivityPercentage.toFixed(1)}`, 455, init + yOffset * index);
          doc.text(`${el.quizzes.score}`, 540, init + yOffset * index, {
            align: 'center',
          });
          doc.text(`${getQuizPercentage.toFixed(1)}`, 580, init + yOffset * index);
          doc.text(`${el.exam.score}`, 670, init + yOffset * index, {
            align: 'center',
          });
          doc.text(`${getExamPercentage.toFixed(1)}`, 720, init + yOffset * index);
          doc.text(`${finalNote.toFixed(1)}`, 820, init + yOffset * index);
          const desempeno = el.enrollment?.extra_final_note
            ? el.enrollment?.extra_final_note
            : el.recuperationQuizEnrollment
            ? el.recuperationQuizEnrollment.note / 10
            : finalNote;
          doc.text(
            `${
              desempeno <= 6.4
                ? 'BAJO'
                : desempeno >= 6.5 && desempeno <= 8.4
                ? 'BÁSICO'
                : desempeno >= 8.5 && desempeno <= 9.4
                ? 'ALTO'
                : 'SUPERIOR'
            }`,
            980,
            init + yOffset * index,
            {
              align: 'center',
            }
          );
          doc.text(
            `${
              el.recuperationQuizEnrollment
                ? (el.recuperationQuizEnrollment.note / 10).toFixed(1)
                : el.enrollment?.extra_score
                ? el.enrollment?.extra_score
                : ''
            }`,
            1120,
            init + yOffset * index
          );
          doc.text(`${(desempeno * 0.25).toFixed(1)}`, 1280, init + yOffset * index);
        });

        doc.text(`${promedio.toFixed(2)}`, 1150, 1545);
        doc.text(
          `${
            promedio.toFixed(2) <= 6
              ? `Puedes mejorar tu rendimiento cada vez mas,\nsigue adelante que vas a mejorar mucho.`
              : `Has hecho un gran trabajo que se ve reflejado en tu\nrendimiento, sigue así.`
          }`,
          70,
          1545
        );

        // Guarda el PDF (reemplaza 'nombre_del_archivo.pdf' con el nombre deseado)

        // // Abre una nueva ventana o pestaña con el certificado
        // const newWindow = window.open('', '_blank')
        // newWindow.document.write(
        //   '<iframe width="100%" height="100%" src="' +
        //     doc.output('datauristring') +
        //     '" download="mi_certificado.pdf"></iframe>'
        // )
        doc.save(`Boletin-${student?.user?.first_name}.pdf`);

        setTimeout(() => {
          // newWindow.document.title = `Boletin - ${user?.first_name}`
          setLoading(false); // Ocultar el ícono de carga
        }, 100); // Ajusta el tiempo según sea necesario
      }, 2000);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        isDisabled={loading}
        isLoading={loading}
        onClick={() => {
          generarCertificado();
          setLoading(true);
        }}
        className="bg-primary text-white dark:bg-klazia dark:text-black md:!text-sm !text-xs !md:px-3 !md:py-3 !px-2 !py-2 !m-0"
      >
        Descargar Boletin {capitalize(period)} Periodo
      </Button>
    </div>
  );
}

export default BoletinPeriodo;
