import React, { useState } from 'react';
import { Text, Select, Box, useToast, Button } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import xhr from 'xhr';
import BasicModal from 'components/modal/Modal';
import AddIndividualStudent from './addIndividualStudent';
import StudentSearch from './studentSearch';

function SubirEstudiantes() {
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const toast = useToast();

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };

  const handleInsert = async () => {
    setUploading(true);
    for (const item of items) {
      const programsResponse = await xhr(`/student-graduateds?filters[document][$eq]=${item.document}`);
      // Verificar si el estudiante ya existe]
      if (programsResponse.data.data.length === 0) {
        // Si el estudiante no existe, insertarlo
        await xhr.post('/student-graduateds', {
          data: item,
        });
      }
    }
    toast({
      title: 'Los estudiantes se subieron correctamente.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    setUploading(false);
  };

  return (
    <>
      <BasicModal setIsOpen={setIsOpen} isOpen={isOpen}>
        <AddIndividualStudent setIsOpen={setIsOpen} />
      </BasicModal>
      <Box w="50%">
        <Text fontSize="28px" fontWeight="bold">
          Subir estudiantes a la lista de graduados
        </Text>
        <Button colorScheme="orange" onClick={() => setIsOpen(true)}>
          Agregar Estudiantes Por Individual
        </Button>
        <br />
        <a
          href="https://klazia.nyc3.digitaloceanspaces.com/plataforma/insertar_graduados_b453df6db5_bd1be649d8.xlsx"
          target="_blank"
          rel="noreferrer"
          style={{ color: 'blue' }}
        >
          Descargar Archivo de ejemplo
        </a>
        <div>
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          />
        </div>
      </Box>

      <Button
        onClick={() => handleInsert()}
        isDisabled={uploading}
        isLoading={uploading}
        colorScheme="messenger"
        marginTop="10px"
      >
        Subir estudiantes
      </Button>

      <StudentSearch />
    </>
  );
}

export default SubirEstudiantes;
