import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Text,
  Progress,
} from '@chakra-ui/react';
import { BiUpload } from 'react-icons/bi';
import useAuth from 'hooks/useAuth';

export default function EditDocumentsModal({ isOpen, onClose, convenio, onUpdate }) {
  const { jwt } = useAuth();
  const [files, setFiles] = React.useState({
    camara_comercio: null,
    cedula: null,
    contract: null,
    rut: null,
  });
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();

  const handleFileChange = (event, fieldName) => {
    setFiles({
      ...files,
      [fieldName]: event.target.files[0],
    });
  };

  const uploadFile = async (file, refId, fieldName) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append('files', file);
    formData.append('ref', 'api::agreement.agreement');
    formData.append('refId', refId);
    formData.append('field', fieldName);

    try {
      const response = await fetch(`https://apiproduction.klazia.com/api/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: formData,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Subir cada archivo si ha sido seleccionado
      const uploadPromises = Object.entries(files).map(([fieldName, file]) => {
        if (file) {
          return uploadFile(file, convenio.id, fieldName);
        }
        return null;
      });

      await Promise.all(uploadPromises);

      toast({
        title: 'Documentos actualizados',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onUpdate(); // Actualizar la lista de convenios
      onClose(); // Cerrar el modal
    } catch (error) {
      toast({
        title: 'Error al actualizar documentos',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Documentos - {convenio?.nombre}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Cámara de Comercio</FormLabel>
                <Input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, 'camara_comercio')} />
                {convenio?.camara_comercio && (
                  <Text fontSize="sm" color="blue.500" mt={1}>
                    <a href={convenio.camara_comercio.url} target="_blank" rel="noreferrer">
                      Ver documento actual
                    </a>
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Documento de Identidad</FormLabel>
                <Input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, 'cedula')} />
                {convenio?.cedula && (
                  <Text fontSize="sm" color="blue.500" mt={1}>
                    <a href={convenio.cedula.url} target="_blank" rel="noreferrer">
                      Ver documento actual
                    </a>
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Contrato</FormLabel>
                <Input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, 'contract')} />
                {convenio?.contract && (
                  <Text fontSize="sm" color="blue.500" mt={1}>
                    <a href={convenio.contract.url} target="_blank" rel="noreferrer">
                      Ver documento actual
                    </a>
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>RUT</FormLabel>
                <Input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, 'rut')} />
                {convenio?.rut && (
                  <Text fontSize="sm" color="blue.500" mt={1}>
                    <a href={convenio.rut.url} target="_blank" rel="noreferrer">
                      Ver documento actual
                    </a>
                  </Text>
                )}
              </FormControl>

              {loading && <Progress size="xs" isIndeterminate />}

              <Button type="submit" colorScheme="blue" isLoading={loading} leftIcon={<BiUpload size={20} />}>
                Actualizar Documentos
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
