import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Collapse,
  Flex,
  Spinner,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Link, useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import xhr from 'xhr';
import dayjs from 'dayjs';

function FiltroSearch() {
  let [searchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fetching, setFetching] = React.useState(true);
  const [paymentsCount, setPaymentsCount] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setFetching(true);

      const resStrapi = await xhr(
        `/students/generalFilter?status=${searchParams.get('status')}&semester=${searchParams.get(
          'semester'
        )}&program=${searchParams.get('program')}&program_type=${searchParams.get(
          'program_type'
        )}&page=${currentPage}&pageSize=5`
      );

      setTotal(resStrapi.data.total);
      setPaymentsCount(resStrapi.data.pageCount);

      setStudents(resStrapi.data.students);
      setFetching(false);
    } catch (error) {
      console.error('Error al obtener datos:', error);
      setFetching(false);
    }
  };

  const toggleExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    if (currentPage > 1) {
      getData();
    }
  }, [currentPage]);

  console.log('students', students);

  return (
    <>
      {
        <>
          <Box fontSize="18px" my="5px">
            Total de estudiantes: {total ? total : ''} - {searchParams.get('program_type')}
          </Box>

          <TableContainer mt="10px" maxHeight={'600px'} overflowY={'auto'}>
            <Table variant="simple" size={'md'}>
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombres</Th>
                  <Th>Fechas</Th>
                  <Th>Materias</Th>
                  <Th>Examen Final</Th>
                  <Th>Requisitos</Th>
                  <Th>Pagos</Th>
                  <Th>Ver</Th>
                </Tr>
              </Thead>
              <Tbody>
                {fetching ? (
                  <Tr>
                    <Td colSpan={6}>
                      <Box margin={7}>
                        <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                          <Spinner />
                        </Flex>
                      </Box>
                    </Td>
                  </Tr>
                ) : students.length === 0 ? (
                  <Box>No hay estudiantes para este criterio de busqueda</Box>
                ) : (
                  students.map((student) => (
                    <React.Fragment key={student.id}>
                      <Tr>
                        <Td>{student.student.id}</Td>
                        <Td>
                          {student.student?.user?.first_name} {student.student?.user?.last_name}
                        </Td>
                        <Td>
                          Inicio: {dayjs(student.createdAt).format('YYYY-MM-DD')}
                          <br /> Última Sesión: {dayjs(student.last_login).format('YYYY-MM-DD')}
                        </Td>
                        <Td>
                          <Box>
                            <Grid templateColumns="repeat(2, 1fr)" maxHeight={'150px'} overflowY="auto" gap={3}>
                              {student.subjects.map((subject) => (
                                <GridItem colSpan={1}>
                                  <Button
                                    key={subject.subject.id}
                                    size="sm"
                                    onClick={() => toggleExpand(`${student.id}-${subject.subject.id}-activities`)}
                                    m={1}
                                  >
                                    {subject.subject.name}
                                  </Button>
                                </GridItem>
                              ))}
                            </Grid>
                          </Box>
                        </Td>
                        <Td>{student.subjects[0]?.exam?.score || 'N/A'}</Td>
                        <Td>
                          <Box>
                            <Grid templateColumns="repeat(1, 1fr)" maxHeight={'150px'} overflowY="auto" gap={3}>
                              {student.requirements?.map((req) =>
                                req.requirements_many?.map((el) => (
                                  <GridItem colSpan={1}>
                                    <Link to={req.file?.url} target="_blank" rel="noreferrer">
                                      <Box p={4} bg="gray.100" rounded="md">
                                        Estado: {req.status}
                                        <br />
                                        Método: {req.method}
                                        <br />
                                        {el.name}
                                      </Box>
                                    </Link>
                                  </GridItem>
                                ))
                              )}
                            </Grid>
                          </Box>
                        </Td>
                        <Td>
                          <Box>
                            <Grid templateColumns="repeat(1, 1fr)" maxHeight={'150px'} overflowY="auto" gap={3}>
                              {student.payments?.map((payment) => (
                                <GridItem colSpan={1}>
                                  <Box p={4} bg="gray.100" rounded="md">
                                    Monto: {payment.ammount}
                                    <br />
                                    Fecha: {dayjs(payment.payment_date).format('YYYY-MM-DD')}
                                    <br />
                                    Concepto:
                                    {payment.other_payments?.map((el) => (
                                      <>
                                        {el.name},<br />{' '}
                                      </>
                                    ))}
                                  </Box>
                                </GridItem>
                              ))}
                            </Grid>
                          </Box>
                        </Td>
                        <Td>
                          <Link to={`/plataforma/administrador/ver-estudiante/${student.id}`}>
                            <Button size="sm">Ver</Button>
                          </Link>
                        </Td>
                      </Tr>
                      {student.subjects.map((subject) => (
                        <React.Fragment key={`details-${subject.subject.id}`}>
                          <Tr>
                            <Td colSpan={7} p={0}>
                              <Collapse in={expandedRows[`${student.id}-${subject.subject.id}-activities`]}>
                                <Flex gap={8}>
                                  <Box
                                    p={4}
                                    bg="gray.100"
                                    height={'fit-content'}
                                    rounded="md"
                                    paddingX={7}
                                    paddingY={4}
                                  >
                                    <strong>
                                      Progreso :{' '}
                                      {subject.progress ? (subject.progress > 100 ? '100' : subject.progress) : 0} / 100
                                    </strong>
                                  </Box>
                                  <Box
                                    p={4}
                                    bg="gray.100"
                                    height={'fit-content'}
                                    rounded="md"
                                    paddingX={7}
                                    paddingY={4}
                                  >
                                    <strong>Quices de {subject.subject.name}:</strong>
                                    <Box>
                                      {subject.quizzes.length > 0 ? (
                                        <ul>
                                          {subject.quizzes.map((quiz) => (
                                            <li key={quiz.id}>
                                              {quiz.quiz.title} - Nota: <b>{quiz.score}</b>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <p>No hay quices disponibles.</p>
                                      )}
                                    </Box>
                                  </Box>

                                  <Box
                                    p={4}
                                    bg="gray.100"
                                    height={'fit-content'}
                                    rounded="md"
                                    paddingX={7}
                                    paddingY={4}
                                  >
                                    <strong>Actividades de {subject.subject.name}:</strong>
                                    <Box>
                                      {subject.activities.length > 0 ? (
                                        <ul>
                                          {subject.activities.map((activity) => (
                                            <li key={activity.id}>
                                              {activity.activity.title} - Nota: <b>{activity.score}</b>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <p>No hay actividades disponibles.</p>
                                      )}
                                    </Box>
                                  </Box>
                                </Flex>
                              </Collapse>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex justifyContent="flex-end" marginTop="sm">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                setCurrentPage(e.selected + 1);
              }}
              pageRangeDisplayed={2}
              pageCount={paymentsCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="containerReactPaginate"
              pageClassName="liReactPaginate"
            />
          </Flex>

          <style jsx>{`
            .containerReactPaginate {
              display: flex;
              width: inherit;
              justify-content: space-evenly;
              list-style: none;
              width: 300px;
            }

            .selected {
              color: #574feb;
              font-weight: bold;
            }
          `}</style>
        </>
      }
    </>
  );
}

export default FiltroSearch;
