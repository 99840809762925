import React from 'react';
import { Box, Button, Text, Input, Select, useToast, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import xhr from 'xhr';
import * as Yup from 'yup';
import FormLiveClasses from './formLiveClasses';

export default function ReplayModal(props) {
  const { live, semester, setReload, reload, setIsOpen, user } = props;

  const [recorded, setRecorded] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);
  const toast = useToast();

  const modalRef = React.useRef();

  console.log('live', live);

  React.useEffect(() => {
    (async () => {
      if (live.id) {
        try {
          const res = await xhr(`/recorded-classes?filters[live_class][id][$eq]=${live?.id}`);
          setRecorded(res.data.data[0]);
        } catch (error) {
          console.error(error);
        } finally {
          setFetching(false);
        }
      }
    })();
  }, [live.id]);

  const BasicInfoSchema = Yup.object().shape({
    description: Yup.string().required('Requerido'),
    semester: Yup.string().required('Requerido'),
    video: Yup.string().required('Requerido'),
  });

  const insertClass = async (values, action) => {
    try {
      setLoading(true);

      console.log('liveInsert', live);

      const response = await xhr.post(`/recorded-classes/`, {
        data: {
          ...values,
          live_class: live.id,
          class_group: live.class_group?.id,
        },
      });

      toast({
        title: 'La grabación se creó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateClass = async (values, action) => {
    try {
      setLoading(true);
      console.log('liveUpdate', live);

      const response = await xhr.put(`/recorded-classes/${recorded.id}`, {
        data: {
          ...values,
          class_group: live.class_group?.id,
        },
      });

      toast({
        title: 'La grabación se actualizó correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxHeight="650px" minH="650px" overflowY="scroll">
      <Box display="flex" justifyContent="space-between" borderBottom="1px solid gray" marginBottom="20px">
        <Box>
          <Text fontWeight="bold" fontSize="20px">
            {live?.title}
          </Text>
        </Box>
      </Box>

      {fetching ? (
        <Box display="flex" justifyContent="center" mt="30%">
          <Spinner size="xl" />
        </Box>
      ) : (
        <>
          {user?.school ? (
            ''
          ) : (
            <Box border="1px solid gray" borderRadius="10px" padding="10px" marginBottom="20px">
              <Box marginTop="-10px" marginBottom="10px">
                <Text fontWeight="bold" fontSize="20px">
                  Grabación de la clase
                </Text>
              </Box>
              <Formik
                initialValues={{
                  description: recorded?.description || '',
                  semester: recorded?.semester || '',
                  video: recorded?.video || '',
                }}
                validationSchema={BasicInfoSchema}
                onSubmit={!recorded ? insertClass : updateClass}
                enableReinitialize
              >
                {() => {
                  return (
                    <Form>
                      <Grid gap={5}>
                        <GridItem colSpan={6}>
                          <Field
                            name="description"
                            label="Descripción"
                            color="black"
                            placeholder="Descripción"
                            as={Input}
                          />
                        </GridItem>

                        <GridItem colSpan={6}>
                          <Field name="semester" label="Semestre" as={Select} placeholder="Seleccionar">
                            {semester?.map((el) => (
                              <>
                                <option value={el.value}> {el.label}</option>
                              </>
                            ))}
                          </Field>
                        </GridItem>

                        <GridItem colSpan={12}>
                          <Field name="video" label="URL Video" color="black" placeholder="URL Video" as={Input} />
                        </GridItem>
                      </Grid>

                      <Button
                        type="submit"
                        colorScheme="#574feb"
                        bg="#574feb"
                        mt="20px"
                        width="140px"
                        isLoading={loading}
                      >
                        {!recorded ? 'Crear' : 'Actualizar'}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          )}

          <Box border="1px solid gray" borderRadius="10px" padding="10px">
            <Box marginTop="0px" marginBottom="20px">
              <Text fontWeight="bold" fontSize="20px">
                Actualizar datos de la clase
              </Text>
            </Box>
            <FormLiveClasses
              modalRef={modalRef}
              live={live}
              semester={semester}
              setReload={setReload}
              reload={reload}
              setIsOpen={setIsOpen}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
