import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  InputGroup,
  InputLeftElement,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import xhr from 'xhr';
import { Search2Icon } from '@chakra-ui/icons';

export default function StudentSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const timeoutRef = useRef(null);

  const searchStudents = async (document) => {
    if (!document) return;

    try {
      setLoading(true);
      setError('');
      const response = await xhr.get(`/student-graduateds?filters[document][$eq]=${document}`);
      setStudents(response.data.data);
    } catch (err) {
      setError('Error al buscar estudiantes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (searchTerm.trim() === '') {
      setStudents([]);
      return;
    }

    timeoutRef.current = setTimeout(() => {
      searchStudents(searchTerm);
    }, 500);

    return () => clearTimeout(timeoutRef.current);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box p={4}>
      <Box mb={6}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Buscar Estudiante
        </Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Search2Icon size={20} />
          </InputLeftElement>
          <Input placeholder="Buscar por documento" value={searchTerm} onChange={handleSearch} maxWidth="400px" />
        </InputGroup>
      </Box>

      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" my={8}>
          <Spinner />
        </Box>
      ) : students.length > 0 ? (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Documento</Th>
                <Th>Tipo Doc.</Th>
                <Th>Institución</Th>
                <Th>Programa</Th>
                <Th>Estado</Th>
                <Th>Fecha límite de pago</Th>
                <Th>Valor</Th>
                <Th>Semestre</Th>
              </Tr>
            </Thead>
            <Tbody>
              {students.map((student) => (
                <Tr key={student.id}>
                  <Td>{student.name}</Td>
                  <Td>{student.document}</Td>
                  <Td>{student.type_document}</Td>
                  <Td>{student.institution}</Td>
                  <Td>{student.program_finished}</Td>
                  <Td>{student.status}</Td>
                  <Td>{student.datePayment}</Td>
                  <Td>{student.valor}</Td>
                  <Td>{student.semester}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : searchTerm ? (
        <Box textAlign="center" my={8}>
          <Text>No se encontraron estudiantes</Text>
        </Box>
      ) : null}
    </Box>
  );
}
