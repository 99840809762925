import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { useSWRConfig } from 'swr';
import { Link } from 'react-router-dom';
import xhr from 'xhr';
import getWelcomelist from 'api/administrador/getWelcomeList';
import Loading from 'components/loading/Loading';
import dayjs from 'dayjs';

export default function BienvenidasAdmin() {
  const [checking, setChecking] = React.useState('');
  const { mutate: mutateGlobal } = useSWRConfig();

  const toast = useToast();

  const { data: welcomeList, mutate } = useSWR('getWelcomeList', getWelcomelist, {
    revalidateOnFocus: false,
  });

  const handleStudentCheck = async (item) => {
    try {
      setChecking(item.enrollment.id);
      await xhr.put(`/${item.program.type}-enrollments/${item.enrollment.id}`, {
        data: {
          welcome: true,
        },
      });

      mutate(
        welcomeList.filter((el) => el.enrollment.id !== item.enrollment.id),
        { revalidate: false }
      );
      mutateGlobal('statsAdmin');
      toast({
        title: '¡La actualización se realizó correctamente!.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
    } finally {
      setChecking('');
    }
  };

  if (!welcomeList) return <Loading />;

  console.log('welcomeList', welcomeList);

  return (
    <>
      <Box fontSize="29px" fontWeight="bold">
        Bienvenidas a estudiantes nuevos
      </Box>

      <Box mt="15px">
        <Box>
          <TableContainer>
            <Table marginY="sm">
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombre</Th>
                  <Th>Teléfono</Th>
                  <Th>Programa</Th>
                  <Th>Fecha</Th>
                  <Th>Perfil</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {welcomeList.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item.student.id}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${item.student.user.first_name} ${item.student.user.last_name}`}
                        </Box>
                      </Td>
                      <Td>{`${item.student.user.cellphone}`}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${item.program.name}`}
                        </Box>
                      </Td>
                      <Td>{dayjs(item.enrollment.createdAt).format('YYYY-MM-DD')}</Td>
                      <Td>
                        <Link
                          style={{ color: 'blue' }}
                          to={`/plataforma/administrador/ver-estudiante/${item.student.id}`}
                        >
                          Ver
                        </Link>
                      </Td>
                      <Td>
                        <Button onClick={() => handleStudentCheck(item)} isLoading={checking === item.enrollment.id}>
                          Verificar
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
