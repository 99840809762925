import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { useSWRConfig } from 'swr';
import { Link } from 'react-router-dom';
import xhr from 'xhr';
import getConvenios from 'api/administrador/getConvenios';
import Loading from 'components/loading/Loading';
import EditDocumentsModal from './EditDocumentsModal';
import { FaPencilAlt } from 'react-icons/fa';

export default function ConveniosAdmin() {
  const [checking, setChecking] = React.useState('');
  const { mutate: mutateGlobal } = useSWRConfig();
  const [selectedConvenio, setSelectedConvenio] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toast = useToast();

  const { data: welcomeList, mutate } = useSWR('getConvenios', getConvenios, {
    revalidateOnFocus: false,
  });

  if (!welcomeList) return <Loading />;

  const handleEditClick = (convenio) => {
    setSelectedConvenio(convenio);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedConvenio(null);
    setIsModalOpen(false);
  };

  const handleUpdate = () => {
    mutate();
  };
  return (
    <>
      <Box fontSize="29px" fontWeight="bold">
        Lista de convenios
      </Box>

      <Box mt="15px">
        <Box>
          <TableContainer>
            <Table marginY="sm">
              <Thead bg="gray.200">
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombre</Th>
                  <Th>Email</Th>
                  <Th>Documentos</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {welcomeList.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item.id}</Td>
                      <Td>
                        <Box whiteSpace="break-spaces" width="180px">
                          {`${item.nombre}`}
                        </Box>
                      </Td>
                      <Td>{`${item.email}`}</Td>
                      <Td>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          variant="ghost"
                          leftIcon={<FaPencilAlt size={16} />}
                          onClick={() => handleEditClick(item)}
                        >
                          Editar Docs
                        </Button>
                      </Td>

                      <Td>
                        <Link style={{ color: 'blue' }} to={`/plataforma/administrador/convenios/${item.id}`}>
                          Ver Estudiantes
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <EditDocumentsModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        convenio={selectedConvenio}
        onUpdate={handleUpdate}
      />
    </>
  );
}
